import { EChartsType } from 'echarts';
import { lineStyles, barStyles, itemColor } from './config';
import { setRotate, minAndMax } from '@/utils/chart';
import { PieItem } from '@/pages/Z-ProjectDetail/views/C-TenantAnalysis/types';

export interface lineData {
  name: string;
  value: string;
}

export interface ChartData {
  xAxis: string[];
  data: { name: string; data: lineData[] }[];
}

interface Params {
  chartData: ChartData | null;
  eChartInstance?: EChartsType;
  config?: any;
}

export const getLineOptions = (params: Params) => {
  const { chartData, eChartInstance, config = {} } = params;
  if (!eChartInstance) {
    return {};
  }
  const color = config.color || ['#FF838B'];
  const { xAxis, data } = chartData || { xAxis: [], data: [] };
  const series = data.map((item, index) => {
    return {
      type: 'line',
      smooth: true,
      name: item.name,
      z: 10,
      data: item.data.map(({ name, value }) => [name, value === null ? '-' : value]),
      symbol: 'emptyCircle', // 拐点设置为空心
      symbolSize: 6,
      emphasis: {
        scale: true,
        itemStyle: {
          color: color[0],
          borderWidth: 4,
        },
      },
      ...lineStyles[data.length === 1 ? 2 : index], // 单线折线图 颜色用乳白色
    };
  });
  const options = {
    color,
    title: {
      show: xAxis.length === 0,
      text: '暂无数据',
      left: 'center',
      top: 'center',
      textStyle: {
        color: '#909399',
        fontSize: 16,
        fontWeight: 400,
      },
    },
    tooltip: {
      trigger: 'axis',
      confine: true,
      // showContent: false,
      // formatter(params) {
      //   return params.value;
      // },
      formatter: (params: any) => {
        const [{ axisValue, seriesName, value }] = params;
        return `
          <div>
            ${axisValue}<br />
            <span style="display:inline-block;width:10px;height:10px;border-radius: 10px;background: ${color};"></span>
            <span style="display:inline-block;width:${seriesName.length * 12 + 20}px">
              ${seriesName}
            </span>
            ${value[1]}%
          </div>
        `;
      },
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: 'line', // 默认为直线，可选为：'line' | 'shadow'
        z: 9,
      },
    },
    legend: {
      show: true,
      top: 15,
      right: 0,
      // icon: 'rect',
      itemWidth: 22,
      itemHeight: 0,
      data: data.map(({ name }, index) => {
        const {
          lineStyle: { type },
        } = lineStyles[data.length === 1 ? 2 : index]; // 单线折线图 颜色用乳白色
        return {
          name,
          itemStyle: {
            borderColor: color,
            borderType: type,
            borderWidth: 2,
          },
        };
      }),
      textStyle: {
        color: '#4A4E57',
        fontSize: 12,
      },
    },
    grid: {
      left: 10,
      right: '5%',
      bottom: 5,
      top: 50,
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: xAxis,
        show: xAxis.length !== 0,
        boundaryGap: true,
        axisTick: {
          show: false,
        },
        axisLabel: {
          textStyle: {
            color: '#525252',
            fontSize: 12,
          },
          interval: 0, // Math.floor(xAxis.length / 12),
          align: 'center',
          verticalAlign: 'top',
          margin: 12,
          rotate: 0,
          formatter: (value: any) => value, // value.split('-').reverse().join('\n'),
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: '#EDEDED',
          },
        },
        splitLine: {
          show: false,
          //  改变轴线颜色
          lineStyle: {
            // 使用深浅的间隔色
            type: 'solid',
            color: ['#EDEDED'],
          },
        },
      },
    ],
    yAxis: [
      {
        // ...this.yName,
        // name: '单位：%',
        // nameLocation: 'end',
        // nameTextStyle: {
        //   padding: [0, 24, 4, 0],
        //   fontWeight: 400,
        // },
        scale: true,
        show: xAxis.length !== 0,
        ...minAndMax(series as any),
        axisLabel: {
          // TODO 添加单位
          formatter: true ? `{value}%` : '{value}',
          textStyle: {
            color: '#525252',
          },
          // margin: 20,
        },
        axisLine: { show: false },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          //  改变轴线颜色
          lineStyle: {
            // 使用深浅的间隔色
            color: ['#EBECEC'],
          },
        },
      },
    ],
    series,
    ...config,
  };

  setRotate(options, eChartInstance);
  return options;
};

export const getBarOptions = (params: Params) => {
  const { chartData, eChartInstance, config = {} } = params;
  if (!eChartInstance) {
    return {};
  }
  const color = config.color || ['#FF838B'];

  const { xAxis, data } = chartData || { xAxis: [], data: [] };
  const series = [];
  data.forEach((item, index) => {
    const { name, data } = item;
    series.push(
      Object.assign(
        {
          type: 'bar',
          symbol: 'circle',
          stack: 'bar',
          z: 10,
          // barWidth: this.barWidth, // 柱图宽度
          barMaxWidth: 16,
          name,
          data: data.map(({ name, value }) => [name, value === null ? '-' : value]),
          emphasis: {
            focus: 'none',
            itemStyle: {
              color: color[0] === '#FF838B' ? '#E30613' : '#424242',
            },
          },
          markArea: {
            silent: true,
            data: [
              [
                {
                  itemStyle: {
                    color: '#EEEFF0',
                  },
                  yAxis: 0,
                },
                {
                  yAxis: Number.MIN_SAFE_INTEGER,
                },
              ],
            ],
          },
          markLine: {
            silent: true,
            symbol: 'none',
            symbolSize: 0,
            lineStyle: {
              type: 'solid',
            },
            data: [
              {
                itemStyle: {
                  color: '#525252',
                },
                label: {
                  show: false,
                },
                yAxis: 0,
              },
            ],
          },
        },
        barStyles[index]
      )
    );
  });
  const options = {
    color,
    title: {
      show: xAxis.length === 0,
      text: '暂无数据',
      left: 'center',
      top: 'center',
      textStyle: {
        color: '#909399',
        fontSize: 16,
        fontWeight: 400,
      },
    },
    tooltip: {
      trigger: 'axis',
      confine: true,
      // showContent: false,
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: 'line', // 默认为直线，可选为：'line' | 'shadow'
      },
      formatter: (params: any) => {
        const [{ axisValue, seriesName, value }] = params;
        return `<div>
          ${axisValue}<br />
          <span style="display:inline-block;width:10px;height:10px;border-radius: 10px;background: ${color};"></span>
          <span style="display:inline-block;width:${
            seriesName.length * 12 + 20
          }px">${seriesName}</span>${value[1]} %</div>`;
      },
    },
    legend: {
      show: true,
      top: 15,
      right: 0,
      borderRadius: 0,
      data: data.map(({ name }, index) => ({
        name,
        ...barStyles[index],
      })),
      itemWidth: 12,
      itemHeight: 12,
      icon: 'rect',
      textStyle: {
        color: '#4A4E57',
        fontSize: 12,
      },
    },
    grid: {
      // left: '3%',
      left: 10,
      right: '5%',
      bottom: 5,
      top: 50,
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: xAxis,
        show: xAxis.length !== 0,
        axisTick: {
          show: false,
        },
        axisLabel: {
          // formatter: (value) => value.split('-').reverse().join('\n'),
          textStyle: {
            color: '#525252',
            fontSize: 12,
          },
          align: 'center',
          verticalAlign: 'top',
          margin: 12,
          interval: 0,
          // rotate: this.rotate ? 45 : 0,
          rotate: 0,
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: '#EDEDED',
          },
        },
      },
    ],
    yAxis: [
      {
        // name: '单位：%',
        // nameLocation: 'end',
        // nameTextStyle: {
        //   padding: [0, 24, 4, 0],
        //   fontWeight: 400,
        // },
        // ...this.yName,
        show: xAxis.length !== 0,
        // scale: true,
        // splitNumber: 5,
        // max:(value) => Math.ceil(value.max),
        // min:(value) => Math.floor(value.min),
        ...minAndMax(series),
        // min: (value) => {
        //   const maxV = Math.ceil(value.max);
        //   const minV = Math.floor(value.min);
        //   const v = Math.floor(maxV - minV) / 5;
        //   return Math.ceil(minV - v);
        // },
        axisLabel: {
          formatter: true ? `{value}%` : '{value}',
          textStyle: {
            color: '#525252',
          },
          margin: 20,
        },
        axisLine: { show: false },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          //  改变轴线颜色
          lineStyle: {
            // 使用深浅的间隔色
            color: ['#EDEDED'],
          },
        },
      },
    ],
    series,
  };

  setRotate(options, eChartInstance);

  return options;
};

export const getPieChartPotions = (
  name: string,
  data: any,
  threshold: number,
  distanceToLabelLine: number,
  unit?: string,
  radius?: string[]
) => {
  const series = [
    {
      name,
      type: 'pie',
      radius,
      center: ['50%', '40%'],
      data,
      labelLine: {
        show: false,
      },
      label: {
        show: true,
        position: 'outside',
        distanceToLabelLine: distanceToLabelLine,
        formatter: (params: any) => {
          return params.percent > threshold ? `${params.percent.toFixed(1)}%` : '';
        },
        color: '#01151D',
      },
      emphasis: {
        focus: 'self',
        label: {
          show: true,
          position: 'inside',
          formatter(params: any) {
            const { percent } = params;
            return `${Math.round(percent * 10) / 10}%`;
          },
          color: '#01151D',
        },
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      blur: {
        itemStyle: {
          opacity: 0.4,
        },
      },
    },
  ];

  return {
    color: itemColor,
    tooltip: {
      confine: true,
      formatter: (params: any) => {
        const { name: label, value, color } = params;
        return `<div>
          ${name}<br />
          <span style="display:inline-block;width:10px;height:10px;border-radius: 10px;background: ${color};"></span>
          ${label} ${value} ${unit}</</div>`;
      },
    },
    legend: {
      itemWidth: 12,
      itemHeight: 14,
      height: 40,
      icon: 'rect',
      bottom: 0,
      left: '0',
      textStyle: {
        color: '#626468',
      },
      orient: 'horizontal',
      type: 'scroll',
      data: data.map((item: PieItem) => item.name),
    },
    series,
  };
};
