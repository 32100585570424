import { HeatMap } from '@/components';
import useIndustrialStore from '@/pages/D-IndustrialAnalysis/views/B-IndustryDistribution/store';
import { memo, useMemo } from 'react';

export default memo(() => {
  const { mapMode, propertyList } = useIndustrialStore((state) => ({
    mapMode: state.mapMode,
    propertyList: state.propertyList,
  }));
  const showHeatMap = mapMode === 'heatMapMode' && propertyList.length > 0;

  const heatMapDataList = useMemo(() => {
    return propertyList.map((property) => {
      const { id, center, tenantCount } = property;
      const [lng, lat] = center;
      return {
        id,
        lng,
        lat,
        count: tenantCount,
      };
    });
  }, [propertyList]);

  return (
    showHeatMap && (
      <HeatMap
        key={mapMode}
        dataList={heatMapDataList}
        max={100}
        options={{
          visible: showHeatMap,
          radius: 25,
          zooms: [0, 13],
          gradient: {
            0: '#1572D0',
            0.5: '#1572D0',
            0.7: '#75D516',
            0.85: '#FFCE1B',
            1: '#E40917',
          },
        }}
      />
    )
  );
});
