import React, { useEffect, useState } from 'react';
import {
  DataStateType,
  DataStateTypeMap,
  TableColumnKey,
  SummaryShowType,
  UnitType,
  tableTitleConfig,
} from '../../config';
import { TableDataItem } from '../../types/settingTypes';
import styles from './style.module.less';
import { JLLSvg, Spin, Tooltip } from 'jlld';
import classNames from 'classnames';
import { UnitExchange, Empty } from '@/components';
import { formatField, formatFieldWithReplace } from '@/utils/tool';
import { getUnit } from '../../method';

export interface CommonDataAnalysisTableProps {
  data?: {
    data: Record<string, TableDataItem[]> | null;
    summaryData: TableDataItem | null;
  } | null;
  dimensionValues: DataStateType[];
  dataIndicator: TableColumnKey[];
  unitType: UnitType;
  summaryShowType: SummaryShowType[];
  loading?: boolean;
  isFutureSupply?: boolean;
}

const getValue = (key: string, value: number | string | null) => {
  if (tableTitleConfig[key]?.controlByCommonConfig) {
    return (
      <UnitExchange
        rentKey={tableTitleConfig[key].controlByCommonConfig!}
        value={value || ''}
      />
    );
  }
  return key === 'newSupply' ? formatFieldWithReplace(value, '0') : formatField(value);
};

const getTooltip = (tip: string | undefined) => {
  if (tip) {
    return (
      <Tooltip
        placement="top"
        title={tip}
      >
        <JLLSvg
          style={{ marginLeft: 4, cursor: 'pointer' }}
          icon="help"
          size={14}
        />
      </Tooltip>
    );
  }
};

const CommonDataAnalysisTable = (props: CommonDataAnalysisTableProps) => {
  const [$table, setTable] = useState<HTMLDivElement | null>(null);
  const [scrolling, setScrolling] = useState(false);
  useEffect(() => {
    if ($table) {
      const onScroll = () => {
        if ($table.scrollLeft > 0 && !scrolling) {
          setScrolling(true);
        } else if ($table.scrollLeft === 0 && scrolling) {
          setScrolling(false);
        }
      };
      $table?.addEventListener('scroll', onScroll);
      return () => {
        $table?.removeEventListener('scroll', onScroll);
      };
    }
  }, [$table, scrolling]);

  const headNameList: JSX.Element[] = [];
  const rowKeyList: string[] = [];
  props.dimensionValues
    .map((item) => DataStateTypeMap[item])
    .forEach((headName, index) => {
      headNameList.push(
        <th
          key={headName}
          style={{ position: 'sticky', zIndex: 80, left: 120 * index }}
          className={classNames({
            [styles.shadowTd]: index === props.dimensionValues.length - 1,
          })}
        >
          <div
            className={styles.headItem}
            style={{ alignItems: 'flex-start' }}
          >
            {headName}
          </div>
        </th>
      );

      switch (index) {
        case 1: {
          rowKeyList.push('dynamicColTwo');
          break;
        }
        case 2: {
          rowKeyList.push('dynamicColThree');
          break;
        }
      }
    });

  props.dataIndicator.forEach((item) => {
    const { title, tip } = tableTitleConfig[item];
    const $title = (
      <th key={title}>
        <div className={styles.headItem}>
          <div className={styles.headItemTitle}>
            {props.isFutureSupply && item === 'projectCount' ? '未来供应项目个数' : title}
            {getTooltip(tip)}
          </div>
          {getUnit(tableTitleConfig[item], props.unitType)}
        </div>
      </th>
    );
    headNameList.push($title);
    rowKeyList.push(item);
  });
  if (props.loading || !props.data) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin />
      </div>
    );
  }
  const { data, summaryData } = props.data;
  if (!Object.entries(data)?.length && !summaryData?.length) {
    return <Empty />;
  }

  return (
    <div
      ref={($table) => {
        setTable($table);
      }}
      className={styles.dataAnalaysisTableWrap}
    >
      <table className={classNames(styles.dataAnalaysisTable, { [styles.scrolling]: scrolling })}>
        <thead>
          <tr>{headNameList.map((item) => item)}</tr>
        </thead>
        <tbody>
          {summaryData && props.summaryShowType.includes(SummaryShowType.total) && (
            <tr>
              <td
                className={classNames(styles.extraItem, styles.shadowTd)}
                colSpan={props.dimensionValues.length}
                style={{ textAlign: 'left' }}
              >
                总体汇总
              </td>
              {props.dataIndicator.map((item) => (
                <td
                  key={item}
                  className={styles.extraItem}
                >
                  {getValue(item, summaryData[item])}
                </td>
              ))}
            </tr>
          )}
          {data &&
            Object.entries(data).map(([firstValue, rowDataList]) => {
              const hasSubclassSummary = rowDataList.some(
                (item) => item.dynamicColOne === '分类汇总'
              );
              return rowDataList.map((rowData, index) => {
                return (
                  <tr key={index}>
                    {index === 0 && (
                      <td
                        rowSpan={rowDataList.length}
                        style={{
                          textAlign: 'left',
                          borderRight: hasSubclassSummary ? `1px solid #DDDFE1` : 'none',
                          borderBottom: hasSubclassSummary ? '1px solid #DDDFE1' : 'none',
                          position: 'sticky',
                          zIndex: 80,
                          left: 0,
                        }}
                      >
                        {firstValue}
                      </td>
                    )}
                    {rowData.dynamicColOne === '分类汇总' ? (
                      <React.Fragment>
                        <td
                          colSpan={props.dimensionValues.length - 1}
                          className={classNames(styles.shadowTd, styles.extraItem)}
                          style={{
                            textAlign: 'left',
                            position: 'sticky',
                            zIndex: 80,
                            left: 120,
                          }}
                        >
                          分类汇总
                        </td>
                        {props.dataIndicator.map((key) => {
                          return (
                            <td
                              key={key}
                              className={styles.extraItem}
                            >
                              {getValue(key, rowData[key])}
                            </td>
                          );
                        })}
                      </React.Fragment>
                    ) : (
                      rowKeyList.map((key, index) => {
                        const isSticky = index < props.dimensionValues.length - 1;
                        return (
                          <td
                            className={classNames({
                              [styles.shadowTd]: index === props.dimensionValues.length - 2,
                            })}
                            key={key}
                            style={{
                              position: isSticky ? 'sticky' : 'initial',
                              zIndex: 80,
                              left: (index + 1) * 120,
                              textAlign: isSticky ? 'left' : 'right',
                            }}
                          >
                            {getValue(key, rowData[key])}
                          </td>
                        );
                      })
                    )}
                  </tr>
                );
              });
            })}
        </tbody>
      </table>
    </div>
  );
};

export default CommonDataAnalysisTable;
