import { JLLSvg } from 'jlld';
import { OptionItem } from 'jlld/es/JLLSelect';
import { useEffect, useRef } from 'react';
import './style.less';

interface TagsInputProps {
  value?: OptionItem[];
  onChange?: (tags?: OptionItem[]) => void;
  // placeholder?: string;
  // errorMsg?: string;
  // validate?: (tags?: OptionItem[]) => boolean;
  validator?: (tags?: OptionItem[]) => boolean;
  className?: string;
  style?: React.CSSProperties;
  maxCount?: number;
}

const TagsInput = (props: TagsInputProps) => {
  const isFocus = useRef(false);

  const inputRef = useRef<HTMLInputElement>(null);
  // const [showError, setShowError] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && isFocus.current) {
        const value = (e.target as HTMLInputElement).value;
        if (
          !value ||
          props.value?.some((item) => item.value === value) ||
          props.value?.length > props?.maxCount
        ) {
          return;
        }

        const newTags = props.value
          ? [...props.value, { label: value, value }]
          : [{ label: value, value }];
        // if (props.validate) {
        //   if (props.validate(newTags)) {
        //     setShowError(false);
        //   } else {
        //     setShowError(true);
        //     return;
        //   }
        // }
        inputRef.current!.value = '';
        props.onChange?.(newTags);
      }
      // backspace
      if (e.key === 'Backspace' && isFocus.current) {
        if (!inputRef.current?.value && props.value && props.value.length > 0) {
          const newTags = props.value.slice(0, -1);
          props.onChange?.(newTags);
        }
      }
    };
    if (props.value?.length === 0) {
      inputRef.current!.value = '';
    }
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [props.value]);

  const onDelete = (index: number) => {
    const newTags = props.value?.filter((_, i) => i !== index);
    props.onChange?.(newTags);
  };

  return (
    <div
      className={`tagsInputWrap ${props.className ? props.className : ''}`}
      style={props.style}
    >
      <div className="tagsInput">
        {props.value && props.value?.length > 0 && (
          <div className="jllTagsContainer">
            {props.value?.map((item, index) => (
              <div
                className="tagItem"
                key={item.label}
              >
                <span>{item.label}</span>
                <span
                  className="closeButton"
                  onClick={() => onDelete(index)}
                >
                  <JLLSvg
                    icon="x"
                    color="#303133"
                  />
                </span>
              </div>
            ))}
          </div>
        )}
        <input
          ref={inputRef}
          onFocus={() => {
            isFocus.current = true;
          }}
          onBlur={() => {
            isFocus.current = false;
          }}
        />
      </div>
      {/* {props.placeholder && !showError && <div className="tagInputMsg">{props.placeholder}</div>}
      {showError && props.errorMsg && <div className="tagInputMsg error">{props.errorMsg}</div>} */}
    </div>
  );
};

export default TagsInput;
