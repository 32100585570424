import { jllRequest } from '@/utils/request';

// 登陆页面背景图
export function getLoginBgImage() {
  return jllRequest({
    url: '/banner/getBannerInLogin',
    method: 'get',
  });
}

// 登陆
export function login(data: any) {
  return jllRequest({
    url: '/user/login',
    method: 'post',
    data,
  });
}
