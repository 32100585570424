import {
  JLLForm,
  JLLDatePicker,
  JLLMultiSelector,
  Button,
  JLLCityPicker,
  JLLCascader,
  JLLSvg,
  Form,
  Tooltip,
} from 'jlld';
import { useMemo, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import styles from './style.module.less';
import classNames from 'classnames';
import { PickerType } from 'jlld/es/JLLDatePicker';
import InputRange from '@/components/InputRange';
import useCommonOptionsStore from '@/store/commonOptions';
import useUserEvent from '@/hooks/useUserEvent';
import useBlockTradeStore from '../../store';
import { buyerSellerTypeOptions, propertyTypeOptions } from '@/constants/options';
import formContext from './formContext';
import useLeftFormStore, { getTimeRange } from '../../store/leftFormStore';
import useTradeDetailStore from '../../store/tradeDetailStore';
import useTradeStatisticsStore from '../../store/tradeStatisticsStore';
import { AuthCityItem } from '@/types';

export default () => {
  const { useViewForm } = formContext;
  const { form } = useViewForm();
  const { sendUserEvent } = useUserEvent();
  const originCityPickerOptions = useCommonOptionsStore((state: any) => state.cityPickerOptions);
  const { queryDistrictSubmarkets, districts, submarkets, currentTab } = useBlockTradeStore();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [optionsType, setOptionsType] = useState<'districts' | 'submarkets'>('districts'); // ['districts', 'submarkets'
  const [type, setType] = useState<PickerType>('quarter');

  const { leftFormQueryParams, yearList, quarterList, setLeftFormQueryParams } = useLeftFormStore(
    useShallow((state) => ({
      leftFormQueryParams: state.leftFormQueryParams,
      quarterList: state.quarterList,
      yearList: state.yearList,
      setLeftFormQueryParams: state.setLeftFormQueryParams,
    }))
  );

  const minMaxDate =
    type === 'quarter' && quarterList?.length
      ? [quarterList?.[0], quarterList?.[quarterList?.length - 1]]
      : yearList?.length
        ? [yearList?.[0], yearList?.[yearList?.length - 1]]
        : [];

  const initialValues = {
    timeRange: [leftFormQueryParams.startTime, leftFormQueryParams.endTime],
  };

  const { queryDetailList } = useTradeDetailStore();

  const { queryStatisticsList } = useTradeStatisticsStore();

  const districtsOrSubmarketsOptions = optionsType === 'districts' ? districts : submarkets;
  // districtsOrSubmarketsOptions = districtsOrSubmarketsOptions?.filter(
  //   (item) => item.value !== '多地'
  // );

  const cityList = Form.useWatch('cityList', form);

  const cityPickerOptions = useMemo(() => {
    return originCityPickerOptions?.map((item: any) => {
      const newItem = { ...item };
      newItem.children = [
        ...item.children,
        {
          label: '多地',
          value: '多地',
          children: [{ label: '多地', value: '多地' } as AuthCityItem],
        },
      ];
      return newItem;
    });
  }, [originCityPickerOptions]);

  const submit = () => {
    sendUserEvent({ button: '区域筛选-搜索' }, 'e_click');
    form.validateFields().then(() => {
      setLeftFormQueryParams(form.getFieldsValue(), optionsType);

      if (currentTab === 'tradeDetail') {
        queryDetailList({
          current: 1,
        });
      } else if (currentTab === 'tradeStatistics') {
        queryStatisticsList({
          current: 1,
        });
      }
    });
  };

  const reset = () => {
    sendUserEvent({ button: '区域筛选-重置' }, 'e_click');
    form.resetFields();
    submit();
  };

  const validateInputRange = (_: any, value: any) => {
    if (value) {
      const [start, end] = value;
      if ((!start && !end) || (start && end && start <= end)) {
        return Promise.resolve();
      }

      return Promise.reject(new Error('结束值需大于起始值'));
    }
    return Promise.resolve();
  };

  return (
    <div className={classNames(styles.leftFormsContainer, { [styles.collapsed]: collapsed })}>
      <div
        className={styles.collapseButton}
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        <JLLSvg
          className={classNames(styles.collapseButtonIcon)}
          icon="collapse"
        />
      </div>
      <JLLForm
        className={classNames(styles.leftForm)}
        form={form}
        initialValues={initialValues}
        layout="vertical"
        onValuesChange={async (value) => {
          if (value.cityList) {
            if (value.cityList.length > 0) {
              form.setFieldValue('cityList', value.cityList);
              queryDistrictSubmarkets(value.cityList);
            } else {
              form.setFieldValue('districtsSubmarkets', undefined);
            }
          }
          if (value.timeRange && value.timeRange[0].includes('Q')) {
            const [start, end] = value.timeRange;
            if (start < quarterList[0] || end > quarterList[quarterList.length - 1]) {
              form.setFieldValue('timeRange', [
                start < quarterList[0] ? quarterList[0] : start,
                end > quarterList[quarterList.length - 1]
                  ? quarterList[quarterList.length - 1]
                  : end,
              ]);
            }
          }
        }}
      >
        <JLLForm.Item
          label="交易时间"
          name="timeRange"
        >
          <JLLDatePicker.JLLQuarterPicker
            isRange
            rangePickerProps={{ allowClear: false }}
            type="quarter"
            minMaxDate={minMaxDate?.length ? minMaxDate : ['', '']}
            onTypeChange={async (type: PickerType) => {
              setType(type);
              const timeRange = await getTimeRange(type === 'year' ? yearList : quarterList, type);
              form.setFieldValue('timeRange', timeRange);
            }}
          />
        </JLLForm.Item>
        <JLLForm.Item
          name="levelCategory"
          label="资产类型"
        >
          <JLLCascader
            search
            leftWidth={110}
            options={propertyTypeOptions}
            placeholder="请选择资产类型"
          />
        </JLLForm.Item>
        <JLLForm.Item
          name="cityList"
          label="城市"
        >
          <JLLCityPicker
            searchOneTab
            popupClassName={styles.cityPicker}
            mode="multiple"
            placeholder="请选择城市"
            options={cityPickerOptions!}
          />
        </JLLForm.Item>
        <JLLForm.Item
          name="districtsSubmarkets"
          label={
            <div className={styles.formItemLabel}>
              <div style={{ marginRight: 4 }}>行政区/子市场</div>
              <Tooltip
                placement="top"
                title="基于你选择的城市进行二次选择"
              >
                <JLLSvg
                  icon="help"
                  size={18}
                  color="#667579"
                />
              </Tooltip>
            </div>
          }
        >
          <JLLCascader
            disabled={
              !cityList || cityList?.length === 0 || districtsOrSubmarketsOptions.length === 0
            }
            placeholder={
              districtsOrSubmarketsOptions.length > 0
                ? '请选择行政区或子市场'
                : '请选择城市后再选择该选项'
            }
            search
            tabs={[
              { label: '行政区', value: 'districts' },
              { label: '子市场', value: 'submarkets' },
            ]}
            onTabsChange={(type) => {
              form.setFieldValue('districtsSubmarkets', undefined);
              setOptionsType(type as 'districts' | 'submarkets');
            }}
            options={districtsOrSubmarketsOptions}
          />
        </JLLForm.Item>
        <JLLForm.Item
          name="buyerType"
          label="买方企业类型"
        >
          <JLLMultiSelector
            options={buyerSellerTypeOptions}
            placeholder="请选择买方企业类型"
          />
        </JLLForm.Item>
        <JLLForm.Item
          name="sellerType"
          label="卖方企业类型"
        >
          <JLLMultiSelector
            options={buyerSellerTypeOptions}
            placeholder="请选择卖方企业类型"
          />
        </JLLForm.Item>
        <JLLForm.Item
          name="tradeArea"
          label="交易面积(㎡)"
          validateTrigger="onBlur"
          rules={[
            {
              validator: validateInputRange,
            },
          ]}
        >
          <InputRange placeholder="请输入" />
        </JLLForm.Item>
        <JLLForm.Item
          name="tradeTotalPrice"
          label="交易金额(万元)"
          validateTrigger="onBlur"
          rules={[
            {
              validator: validateInputRange,
            },
          ]}
        >
          <InputRange placeholder="请输入" />
        </JLLForm.Item>
        <JLLForm.Item
          name="tradeUnitPrice"
          label="交易单价(万元/㎡)"
          validateTrigger="onBlur"
          rules={[
            {
              validator: validateInputRange,
            },
          ]}
        >
          <InputRange placeholder="请输入" />
        </JLLForm.Item>
      </JLLForm>
      <div className={styles.leftFormFooter}>
        <Button onClick={reset}>重置</Button>
        <Button
          type="primary"
          style={{ marginLeft: 8 }}
          onClick={submit}
        >
          提交
        </Button>
      </div>
    </div>
  );
};
