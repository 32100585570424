import classNames from 'classnames';
import { JLLSvg } from 'jlld';
import styles from './style.module.less';

interface ColumnHeaderWithSortProps {
  title: string;
  arrowAlign?: string;
  currentKey: string;
  sortKey: string;
  sortAsc: boolean;
  sortIconClick: any;
}

const ColumnHeaderWithSort = (props: ColumnHeaderWithSortProps) => {
  const { arrowAlign, title, sortKey, currentKey, sortAsc, sortIconClick } = props;
  return (
    <div
      className={classNames(styles.titleWithSort, arrowAlign === 'right' ? styles.arrowRight : '')}
    >
      <p>{title}</p>
      <div className={styles.sortArea}>
        <div
          className={styles.up}
          onClick={() => sortIconClick(currentKey, true)}
        >
          <JLLSvg
            icon="sort_up"
            size={7}
            color={sortKey === currentKey && sortAsc ? '#01151D' : '#B6C0C2'}
          />
        </div>
        <div
          className={styles.down}
          onClick={() => sortIconClick(currentKey, false)}
        >
          <JLLSvg
            icon="sort_down"
            size={7}
            color={sortKey === currentKey && !sortAsc ? '#01151D' : '#B6C0C2'}
          />
        </div>
      </div>
    </div>
  );
};

export default ColumnHeaderWithSort;
