import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import { thousandSplitNumber, deepClone } from '@/utils/tool';
import useIndustrialStore from '@/pages/D-IndustrialAnalysis/views/B-IndustryDistribution/store';

interface DataType {
  xAxisData: string[];
  selecteds: number[];
  unSelecteds: number[];
}

const SelectedBarChart = (props: { dataType: string }) => {
  const industryCompetitivenessAnalysisList = useIndustrialStore(
    (state) => state.industryCompetitivenessAnalysisList
  );
  const [indusLength, setIndusLength] = useState<number>(5);
  const [convertedData, setConvertedData] = useState<DataType>({
    xAxisData: [],
    selecteds: [],
    unSelecteds: [],
  });
  const [dataByIndustry, setDataByIndustry] = useState({});

  const findLongestString = (arr: string[]) => {
    return arr.reduce((longest: string, current: string) => {
      return current.length > longest.length ? current : longest;
    }, '');
  };

  const sum = (arr: any[], key: string) => {
    return arr.reduce((prev, cur) => {
      return Number(prev) + Number(cur[key]);
    }, 0);
  };

  useEffect(() => {
    const temp: DataType = {
      xAxisData: [],
      selecteds: [],
      unSelecteds: [],
    };
    const tempByIndustry = {} as any;
    deepClone(industryCompetitivenessAnalysisList)?.sort((a: any, b: any) => {
      if (props.dataType === 'area') {
        return b.areaPercentage - a.areaPercentage;
      }
      return b.countPercentage - a.countPercentage;
    });

    industryCompetitivenessAnalysisList?.forEach((item) => {
      temp.xAxisData.push(item.name);
      tempByIndustry[item.name] = item;
      if (item.selecteds) {
        temp.selecteds.push(
          sum(item.selecteds, props.dataType === 'area' ? 'areaPercentage' : 'countPercentage')
        );
      } else {
        temp.selecteds.push(0);
      }

      if (item.unSelecteds) {
        temp.unSelecteds.push(
          sum(item.unSelecteds, props.dataType === 'area' ? 'areaPercentage' : 'countPercentage')
        );
      } else {
        temp.unSelecteds.push(0);
      }
    });
    setIndusLength(findLongestString(temp.xAxisData).length);
    setConvertedData(temp);
    setDataByIndustry(tempByIndustry);
  }, [industryCompetitivenessAnalysisList, props.dataType]);

  const getOption = () => {
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'none',
        },
        formatter(params: any) {
          const data = dataByIndustry[params[0].name];
          let str = `<div style="width: 200px;"><p style="height: 20px; line-height: 16px; color: #01151D; margin-bottom: 8px; font-weight: bold;">
              <span>${data.name}</span>
            </p>
            <p style="display: flex; align-items: center;margin-bottom: 8px;">
            <span style="width: 70px; color: #60666E; flex: 1;">${
              props.dataType === 'area' ? '面积占比' : '个数占比'
            }</span>
            <span style="color: #01151D;">${
              (props.dataType === 'area'
                ? data.areaPercentage?.toFixed(2)
                : data.countPercentage?.toFixed(2)) ?? '--'
            }%</span>
          </p>
          <p style="display: flex; align-items: center;margin-bottom: 8px;">
            <span style="width: 70px; color: #60666E; flex: 1;">${
              props.dataType === 'area' ? '总面积' : '总个数'
            }</span>
            <span style="color: #01151D;">${
              (props.dataType === 'area'
                ? thousandSplitNumber(data.area)
                : thousandSplitNumber(data.count)) ?? '--'
            }
            ${props.dataType === 'area' ? '㎡' : '个'}</span>
          </p>`;
          if (data.selecteds?.length > 0) {
            for (let i = 0; i < data.selecteds?.length; i += 1) {
              str += `
                <p style="display: flex; align-items: center;margin-bottom: 8px;">
                <span style="width: 70px; color: #60666E; flex: 1;">${data.selecteds[i].name}</span>
                <span style="color: #01151D;">${
                  props.dataType === 'area'
                    ? thousandSplitNumber(data.selecteds[i].area)
                    : thousandSplitNumber(data.selecteds[i].count)
                }
                 ${props.dataType === 'area' ? '㎡' : '个'}</span>
              </p>
              `;
            }
          }
          if (data.unSelecteds?.length > 0) {
            for (let j = 0; j < data.unSelecteds?.length; j += 1) {
              str += `
                <p style="display: flex; align-items: center; margin-bottom: 8px;">
                <span style="width: 70px; color: #60666E; flex: 1;">${
                  data.unSelecteds[j].name
                }</span>
                <span style="color: #01151D;">${
                  props.dataType === 'area'
                    ? thousandSplitNumber(data.unSelecteds[j].area)
                    : thousandSplitNumber(data.unSelecteds[j].count)
                }
                 ${props.dataType === 'area' ? '㎡' : '个'}</span>
              </p>
              `;
            }
          }
          return `${str}</div>`;
        },
      },
      legend: {
        icon: 'rect',
        itemWidth: 12,
        itemHeight: 12,
        right: '0px',
      },
      grid: {
        top: '50px',
        left: '30px',
        right: '10px',
        bottom: `${indusLength * 10}px`,
      },
      xAxis: [
        {
          type: 'category',
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 10,
            rotate: 60,
          },
          data: convertedData.xAxisData,
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: '%',
          nameTextStyle: {
            padding: [0, 0, 0, -30],
          },
        },
      ],
      series: [
        {
          name: props.dataType === 'area' ? '被选择行业面积占比(%)' : '被选择行业个数占比(%)',
          color: '#0C7BA1',
          type: 'bar',
          stack: 'indus',
          barWidth: 16,
          emphasis: {
            focus: 'series',
            itemStyle: {
              color: '#0C7BA1',
              opacity: 1,
            },
          },
          blur: {
            itemStyle: {
              opacity: 0.5,
            },
          },
          data: convertedData.selecteds,
        },
        {
          name: props.dataType === 'area' ? '未被选择行业面积占比(%)' : '未被选择行业个数占比(%)',
          color: '#B6C0C2',
          type: 'bar',
          stack: 'indus',
          barWidth: 16,
          emphasis: {
            focus: 'series',
            itemStyle: {
              color: '#B6C0C2',
              opacity: 1,
            },
          },
          blur: {
            itemStyle: {
              opacity: 0.5,
            },
          },
          data: convertedData.unSelecteds,
        },
      ],
    };
    return option;
  };

  return (
    <>
      {industryCompetitivenessAnalysisList?.length > 0 ? (
        <ReactECharts
          option={getOption()}
          notMerge={true}
          lazyUpdate={true}
          style={{ height: `${indusLength * 10 + 280}px` }}
        />
      ) : (
        <div className="chartEmpty">
          <img src="/empty-chart.png" />
          <p>暂无数据</p>
        </div>
      )}
    </>
  );
};

export default SelectedBarChart;
