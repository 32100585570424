import ReactECharts from 'echarts-for-react';
import { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { useDebounceFn } from 'ahooks';
import { message, Spin } from 'jlld';
import { thousandSplitNumber, numFormat, deepClone } from '@/utils/tool';
import { downloadChart } from '@/utils/downloadChart';
import { Empty } from '@/components';

interface ChartDataItem {
  dynamicColOne: string;
  dynamicColTwo: string;
  dynamicColThree: string;
  areaTotal: string;
  areaTotalNum: string;
  areaProportion: string;
  areaProportionSign: string;
  numTotal: string;
  numProportion: string;
  numProportionSign: string;
}

interface DoubleBarChartProps {
  chartData: [];
  valueType: string | undefined;
  downloadImgName?: string;
}

const initOption = {
  // color: ['#FC888F', '#666666'],
  animation: false,
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      // 坐标轴指示器，坐标轴触发有效
      type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
    },
    formatter: (params: any) => {
      const [{ axisValueLabel, color, seriesName, value }] = params;
      let unit = '';
      if (seriesName.includes('占比')) {
        unit = '%';
      } else if (seriesName.includes('面积数值')) {
        unit = '㎡';
      } else {
        unit = '个';
      }
      const temp = `
      <div>
        <div style="color: #60666E;">${axisValueLabel}</div>
        <div style="display:flex;align-items: center;">
          <i style="background: ${color};display:inline-block;width:12px;height:12px;margin-right:8px;"></i>
          <span style="display:inline-block;width:72px;color: #60666E;">${seriesName}</span><span style="color: #303133;">${
            numFormat(value) || '--'
          }${unit}</span>
        </div>
      </div>
      `;
      return temp;
    },
  },
  graphic: {
    type: 'text',
    silent: true,
    left: 'center',
    bottom: 0,
    bounding: 'raw',
    style: {
      fill: '#8d949d',
      text: ['powered by JLL睿见数据', 'JLL睿见数据提供技术及数据支持'].join('\n\n'),
      fontSize: 12,
      lineHeight: 12,
      textAlign: 'center',
    },
  },
  legend: {
    icon: 'rect',
    itemWidth: 12,
    itemHeight: 12,
    right: 'center',
    formatter: (name: string) => {
      let unit = '';
      if (name.includes('占比')) {
        unit = '(%)';
      } else if (name.includes('面积数值')) {
        unit = '(㎡)';
      } else {
        unit = '(个)';
      }
      return `${name}${unit}`;
    },
  },
  axisPointer: {
    link: {
      xAxisIndex: 'all',
    },
  },
  grid: [
    {
      id: 'left-bar',
      bottoom: '5%',
      left: '6%',
      height: 'auto',
    },
    {
      id: 'right-bar',
      bottoom: '5%',
      height: 'auto',
      right: '6%',
    },
  ],
  xAxis: [
    {
      id: 'left-bar',
      type: 'value',
      boundaryGap: false,
      inverse: true,
      position: 'top',
      axisTick: {
        show: false,
      },
      splitLine: { show: true },
    },
    {
      id: 'right-bar',
      type: 'value',
      gridIndex: 1,
      position: 'top',
      boundaryGap: false,
      // axisLine: { onZero: true },
      axisTick: {
        show: false,
      },
      splitLine: { show: true },
    },
  ],
  yAxis: [
    {
      id: 'left-bar',
      type: 'category',
      position: 'right',
      // show: false,
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: false,
        fontSize: 12,
      },
      data: [],
    },
    {
      id: 'right-bar',
      type: 'category',
      gridIndex: 1,
      axisTick: {
        show: false,
      },
      axisLabel: {
        width: 130,
        fontSize: 12,
        formatter: (val) => `{a| ${val}}`,
        padding: [0, 2, 0, 0],
        overflow: 'truncate',
        rich: {
          a: {
            align: 'center',
          },
        },
      },
      axisLine: {
        lineStyle: {
          color: '#525252',
          width: 1, // 这里是为了突出显示加上的
        },
      },
      data: [],
    },
  ],
  series: [],
};

const DoubleBarChart = forwardRef<DoubleBarChartProps, DoubleBarChartProps>((props, ref) => {
  const doubleBarChartRef = useRef<ReactECharts>(null);
  const [chartOption, setChartOption] = useState({});
  const [resizeLoading, setResizeLoading] = useState(false);

  const getNewChartOption = (d: ChartDataItem[], type = 'areaProportion') => {
    const data = d?.concat();
    if (data?.length === 0) return;
    // 指标类型 占比-areaProportion   个数-numProportion
    const yData = [] as any;
    const barData1 = [] as any;
    const barData2 = [] as any;
    const tempOption = initOption;
    data?.sort((a: ChartDataItem, b: ChartDataItem) =>
      type === 'areaProportion'
        ? Number(a?.areaProportion) - Number(b?.areaProportion)
        : Number(a?.areaTotalNum) - Number(b?.areaTotalNum)
    );
    data?.forEach((item) => {
      yData.push(item.dynamicColOne);
      if (type === 'areaProportion') {
        // 占比
        barData1.push(item.areaProportion);
        barData2.push(item.numProportion);
      } else if (type === 'numProportion') {
        // 个数
        barData1.push(item.areaTotal?.split(',').join('') || '');
        barData2.push(item.numTotal?.split(',').join('') || '');
      }
    });
    tempOption.yAxis[0].data = yData;
    tempOption.yAxis[1].data = yData;
    tempOption.series = [
      {
        name: type === 'areaProportion' ? '面积占比' : '面积数值',
        type: 'bar',
        itemStyle: {
          color: '#0C7BA1',
        },
        label: {
          show: true,
          position: 'left',
          formatter:
            type === 'areaProportion'
              ? '{c}%'
              : ({ value }) => thousandSplitNumber(numFormat(value)),
          fontFamily: 'digitRegular',
        },
        barWidth: 32,
        barCategoryGap: 16,
        xAxisId: 'left-bar',
        yAxisId: 'left-bar',
        data: barData1,
      },
      {
        name: type === 'areaProportion' ? '个数占比' : '个数数值',
        type: 'bar',
        itemStyle: {
          color: '#B6C0C2',
        },
        label: {
          show: true,
          position: 'right',
          formatter:
            type === 'areaProportion'
              ? '{c}%'
              : ({ value }) => thousandSplitNumber(numFormat(value)),
          fontFamily: 'digitRegular',
        },
        barWidth: 32,
        barCategoryGap: 16,
        xAxisId: 'right-bar',
        yAxisId: 'right-bar',
        data: barData2,
      },
    ] as any;
    const cwidth = doubleBarChartRef?.current?.ele.clientWidth || 1200;
    const gridWidth = Math.round((cwidth * 0.88 - 140) / 2);
    const tempGrid = tempOption.grid.concat();
    tempGrid[0].width = gridWidth - 1;
    tempGrid[1].width = gridWidth;
    tempOption.grid = tempGrid;
    setChartOption({ ...tempOption });
  };

  const downloadChartImg = () => {
    if (props.chartData.length === 0) {
      message.error('图表暂无数据，不可下载！');
      return;
    }
    downloadChart(doubleBarChartRef, `${Date.now()}${props.downloadImgName}`);
  };

  useImperativeHandle(ref, () => ({
    downloadChartImg,
  }));

  const { run } = useDebounceFn(
    () => {
      setResizeLoading(true);
      getNewChartOption(deepClone(props.chartData), deepClone(props.valueType));
      doubleBarChartRef.current?.getEchartsInstance().resize();
      setTimeout(() => {
        setResizeLoading(false);
      }, 300);
    },
    {
      wait: 300,
    }
  );

  window.addEventListener('resize', run);

  useEffect(() => {
    getNewChartOption(props.chartData, props.valueType);
  }, [props.chartData, props.valueType]);

  return (
    <>
      {props.chartData?.length > 0 && !resizeLoading ? (
        <ReactECharts
          style={{ height: `${props.chartData?.length * 40 + 150}px` }}
          ref={doubleBarChartRef}
          option={chartOption}
          notMerge={true}
          lazyUpdate={false}
        />
      ) : resizeLoading ? (
        <div style={{ margin: '100px', textAlign: 'center' }}>
          <Spin size="large" />
        </div>
      ) : (
        <Empty />
      )}
    </>
  );
});

export default DoubleBarChart;
