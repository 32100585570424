import { PropertyType } from '@/constants/enums';
import { commercialLegends, industryLegends } from './config';
import styles from './style.module.less';
import { Props } from './types';

export default (props: Props) => {
  const { visible, userProperty } = props;
  if (!visible) {
    return null;
  }
  return (
    <div className={styles.propertyLegend}>
      {userProperty.includes(PropertyType.commercial) && (
        <div className={styles.legends}>
          {commercialLegends.map((legend) => (
            <div
              key={legend.name}
              className={styles.legendItem}
            >
              <i
                className={styles.legendIcon}
                style={{ backgroundColor: legend.color }}
              />
              <div className={styles.legendText}>商业办公 · {legend.name}</div>
            </div>
          ))}
        </div>
      )}

      {userProperty.includes(PropertyType.industrial) && (
        <div className={styles.legends}>
          {industryLegends.map((legend) => (
            <div
              key={legend.name}
              className={styles.legendItem}
            >
              <i
                className={styles.legendIcon}
                style={{ backgroundColor: legend.color }}
              />
              <div className={styles.legendText}>产业办公 · {legend.name}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
