
import { useState } from 'react';
import { Popover, JLLSvg, Modal } from 'jlld';
import styles from './styles.module.less';

const DevTypeDialog = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const getContent = () => (
    <div className={styles.devTypePopover}>
      <p className={styles.content}>
        存在4种开发商类型：传统地产开发商、政府/平台开发商、专业产业园开发商、行业龙头企业
      </p>
      <div
        className={styles.forMore}
        onClick={() => setModalOpen(true)}
      >
        了解更多
      </div>
    </div>
  );

  return (
    <>
      <Modal
        open={modalOpen}
        title="开发商类型"
        maskClosable
        centered
        onCancel={() => {
          setModalOpen(false);
        }}
        footer={() => null}
      >
        <div className={styles.devTypeDesc}>
          <p className={styles.title}>传统地产开发商</p>
          <p className={styles.content}>
            传统地产开发商是指专注于开发和销售住宅、商业、办公等房地产项目的企业；传统地产开发商拥有强大的资金实力，以及住宅地产、商业地产领域丰富的开发经验，在土地购置、规划设计、施工建设、营销推广、销售等方面具备综合能力，如万科地产、碧桂园、绿地集团等企业都属于传统地产开发商。
          </p>
          <p className={styles.title}>政府/平台开发商</p>
          <p className={styles.content}>
            政府/平台开发商是指根据城市规划发展需求，以政府为主导、政府支持的平台企业进行产业片区开发、经营和管理的企业，具有政策红利和资源优势，通常以产业地产为载体，产业运营为依托，资金投向园区开发的同时投资入驻园区的企业，能够获得政府政策支持产业招商、人口导入等多方面促进城市产业片区功能建设和发展，如张江高科、中关村发展集团、深圳投资控股有限公司等。
          </p>
          <p className={styles.title}>专业产业园开发商</p>
          <p className={styles.content}>
            专业产业园开发商是指专注于产业地产开发和运营的企业，在园区规划、产品线、产业招商及园区运营等方面已形成品牌及专业经验，能够根据不同的产业特点和需求，进行专业化的定位规划、设计和配套功能建设，以满足企业的定制化需求，面向园区提供一站式的产业服务和园区管理，如招商局蛇口、华夏幸福、联东U谷、亿达中国等。
          </p>
          <p className={styles.title}>行业龙头企业</p>
          <p className={styles.content}>
            行业龙头企业是指在制造业、科技互联网等实体产业领域具有领先地位的企业，具有较强的品牌影响力、技术实力和创新能力等综合实力，能够获区总部用地开发建设总部园区，以企业总部为核心吸引上下游企业汇聚，形成多功能、高效能的产业集聚平台，如阿里巴巴、腾讯、华为等。
          </p>
        </div>
      </Modal>
      <Popover
        content={getContent}
        overlayInnerStyle={{ backgroundColor: '#01151D' }}
      >
        <JLLSvg
          icon="info"
          size={20}
          className={styles.infoIcon}
        />
      </Popover>
    </>
  );
};

export default DevTypeDialog;
