import { useMemo } from 'react';
import useIndustrialStore from '@/pages/D-IndustrialAnalysis/views/B-IndustryDistribution/store';
import { Marker } from '@uiw/react-amap-marker';
import useZoomInRange from '@/hooks/useZoomInRange';
import styles from './style.module.less';

export default () => {
  const showAreaMask = useZoomInRange([13, 16]);
  const { mapMode, propertyList } = useIndustrialStore((state) => ({
    mapMode: state.mapMode,
    propertyList: state.propertyList,
  }));

  const maxArea = useMemo(() => {
    return Math.max(...propertyList.map((property) => property.leaseArea));
  }, [propertyList]);

  return mapMode === 'areaMarksMode' && showAreaMask
    ? [
        propertyList.map((property) => {
          const size = (80 * property.leaseArea) / maxArea;
          const area = `${size > 8 ? size : 8}px`;
          console.log('area', area);
          return (
            <Marker
              key={property.id}
              position={property.center}
              anchor="center"
            >
              <div
                className={styles.areaMark}
                style={{ width: area, height: area }}
              ></div>
            </Marker>
          );
        }),
        <div
          key="legend"
          className={styles.areaMarksLegend}
        >
          注：圆圈大小代表行业的需求面积高低
        </div>,
      ]
    : null;
};
