import { useEffect, useState } from 'react';
import { useForm } from 'jlld/es/form/Form';
import { getDefaultProperty } from '@/pages/F-DataAnalysis/method';
import DataAnalysisTopForm from '@/pages/F-DataAnalysis/components/DataAnalysisTopForm';
import HeadQuarterFormItem from '@/components/CommonFormItems/HeadQuarterFormItem';
import ProjectOwnershipFormItem from '@/components/CommonFormItems/ProjectOwnershipFormItem';
import DevelopersFormItem from '@/components/CommonFormItems/DevelopersFormItem';
import LeedLevelsFormItem from '@/components/CommonFormItems/LeedLevelsFormItem';
import PropertyTypeFormItem from '@/components/CommonFormItems/PropertyTypeFormItem';
import CitysFormItem from '@/components/CommonFormItems/CitysFormItem';
import DistrictsFormItem from '@/components/CommonFormItems/DistrictsFormItem';
import SubmarketsFormItem from '@/components/CommonFormItems/SubmarketsFormItem';
import TimePicker from '@/pages/F-DataAnalysis/components/TimePicker';
import { TopFormValues } from '@/pages/F-DataAnalysis/types/settingTypes';
import useFutureSupplyStore, { queryCurrentTabData, setLoading } from '../../store';
import useFutureSupplySettingStore, {
  setSettingData,
  initTopFormValues,
  getInitValue,
} from '../../store/settingStore';
import { PropertyType } from '@/constants/enums';
import { getFutureMinMaxDate } from '@/utils/tool';

const minMax = getFutureMinMaxDate();
interface TopFormProps {
  tabModuleName: string;
}

const TopForm = (props: TopFormProps) => {
  const defaultProperty = getDefaultProperty();
  const [form] = useForm();
  const topFormValues = useFutureSupplySettingStore((state) => state.topFormValues);
  const defaultDateValue = useFutureSupplySettingStore((state) => state.defaultDateValue);
  const { loading } = useFutureSupplyStore((state) => ({
    loading: state.loading,
  }));
  const [headQuarterDisabled, setHeadQuarterDisabled] = useState(
    defaultProperty[PropertyType.industrial].length > 0
  );

  useEffect(() => {
    initTopFormValues();
    setLoading('topForm', false);
    return () => {
      setLoading('topForm', true);
    };
  }, []);

  return (
    <DataAnalysisTopForm<TopFormValues>
      tabModuleName={props.tabModuleName}
      form={form}
      loading={loading.topForm}
      initialValues={topFormValues}
      initValues={getInitValue()}
      onSubmit={(topFormValues) => {
        setSettingData({ topFormValues });
        queryCurrentTabData();
      }}
      onReset={(topFormValues) => {
        setSettingData({ topFormValues });
        queryCurrentTabData();
      }}
      onValuesChange={async (value) => {
        if (value.propertyType) {
          setHeadQuarterDisabled(value.propertyType[PropertyType.industrial]?.length > 0);
          if (value.propertyType[PropertyType.industrial]?.length > 0) {
            form.setFieldValue('isHeadquarters', []);
          }
        }
      }}
    >
      <CitysFormItem />
      <DistrictsFormItem />
      <SubmarketsFormItem />
      <PropertyTypeFormItem
        exclusive
        notAllowEmpty
        leftAll={false}
      />
      <HeadQuarterFormItem disabled={headQuarterDisabled} />
      <ProjectOwnershipFormItem />
      <DevelopersFormItem />
      <LeedLevelsFormItem />
      <TimePicker
        // typeChangedValue={defaultDateValue}
        minMax={minMax}
      />
    </DataAnalysisTopForm>
  );
};

export default TopForm;
