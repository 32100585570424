import classNames from 'classnames';
import { useEffect, useState } from 'react';
import useLoginStore from '../../store';
import { BackgroundImage } from '../../types';
import styles from './style.module.less';

let timer: any;

export default () => {
  const backgroundImages = useLoginStore((state) => state.backgroundImages);

  const [hoverIndex, setHoverIndex] = useState(0);

  const loop = (index: number) => {
    if (index !== undefined) {
      setHoverIndex(index);
    } else if (hoverIndex >= backgroundImages.length - 1) {
      setHoverIndex(0);
    } else {
      setHoverIndex(hoverIndex + 1);
    }
    timer = setTimeout(loop, 1000);
  };

  const changeBanner = (index: number) => {
    clearTimeout(timer);
    loop(index);
  };

  useEffect(() => {
    if (backgroundImages.length > 0) {
      console.log('loog');
      loop(0);
    }
  }, [backgroundImages]);

  return (
    <div className={styles.backgroundImage}>
      {backgroundImages?.map((item: BackgroundImage, index: number) => {
        return (
          <div
            className={classNames(styles.imageItem, { [styles.active]: hoverIndex === index })}
            key={item.fileId}
            style={{ backgroundImage: `url(${item.fileUrl})` }}
          />
        );
      })}
      <div className={styles.indicator}>
        {backgroundImages?.map((item: BackgroundImage, index: number) => {
          return (
            <div
              className={classNames(styles.dot, { [styles.active]: hoverIndex === index })}
              key={index}
              onMouseOver={() => changeBanner(index)}
            />
          );
        })}
      </div>
    </div>
  );
};
