import { useMemo, useRef } from 'react';
import { useForm, useWatch } from 'jlld/es/form/Form';
import { OptionsToDefaultValues } from 'jlld/es/JLLCascader';
import { useShallow } from 'zustand/react/shallow';
import { Radio, ConfigProvider, JLLSelect, JLLForm, JLLCascader, JLLButtonRadio } from 'jlld';
import { radioButtonConfig } from '@/jll';
import useUserEvent from '@/hooks/useUserEvent';
import useReactShareUserStore from '@/store/reactShareUserStore';
import useReactShareSelectedStore from '@/store/reactShareSelectedStore';
import useRelocateAnalysisStore, {
  setDisplayMode,
  queryData,
  setTopFormQueryParam,
  setBoundariesType,
} from '../../store';
import { queryRelocateAnalysisPageOptions } from '@/store/commonOptions/pageActions';
import { getHalfYearAgoDate, getQuarter, getLastQuarter, getNextQuarter } from '@/utils/tool';
import {
  RelocateTrailDimensionType,
  RelocateAnalysisModeType,
  PropertyType,
  BoundariesType,
} from '@/constants/enums';
import { commercialAndIndustrialOptions } from '@/constants/options';
import { IndustrialPicker } from '../../../B-IndustryDistribution/components/TopForms/commonFormItems';
import DistrictAndSubMarket from '../../../B-IndustryDistribution/components/TopForms/DistrictAndSubMarket';
import DateTimePicker from '@/components/DateTimePicker';
import useUserAuth from '@/hooks/useUserAuth';
import styles from './style.module.less';

interface DistrictAndSubmarket {
  submarket: string[];
  district: string[];
}
interface TopFilterForm {
  dimension: RelocateTrailDimensionType;
  rangeTime: [string, string];
  time: string;
  mode: RelocateAnalysisModeType;
  propertyType: [];
  districtAndSubmarket: DistrictAndSubmarket;
  secondaryIndustries: any;
  city: string;
}

const modeTypeOptions = [
  { label: '地图', value: RelocateAnalysisModeType.map },
  { label: '图表', value: RelocateAnalysisModeType.chart },
];

const [year, quarter] = getLastQuarter()
  .split('-Q')
  .map((item) => Number(item));
const endTimeStr = `${year}-Q${quarter}`;

const RelocateTrailTimeTypeMap = {
  halfYear: getQuarter(getHalfYearAgoDate()),
  oneYear: getNextQuarter(`${year - 1}-Q${quarter}`),
  twoYears: getNextQuarter(`${year - 2}-Q${quarter}`),
  threeYears: getNextQuarter(`${year - 3}-Q${quarter}`),
  custom: 'custom',
};

const generateQueryParam = (formValue: TopFilterForm) => {
  console.log('formValue', formValue);
  const { city, secondaryIndustries, districtAndSubmarket, propertyType } = formValue;

  const param: any = {
    city,
    endTime: endTimeStr,
  };
  if (secondaryIndustries) {
    const primaryIndustries = Object.keys(secondaryIndustries);
    const temp: string[] = [];
    param.primaryIndustries = primaryIndustries;
    primaryIndustries.forEach((keys) => {
      temp.push(...secondaryIndustries[keys]);
    });
    param.secondaryIndustries = temp;
  }

  if (districtAndSubmarket) {
    const { submarket, district } = districtAndSubmarket;
    if (submarket) {
      param.subMarketId = submarket[0];
    } else {
      param.district = district[0];
    }
  }

  if (propertyType) {
    param.projectLevel = propertyType[PropertyType.commercial] || [];
    param.industryProjectLevel = propertyType[PropertyType.industrial] || [];
  }

  const [time, rangeTime] = formValue.time || [];

  if (time) {
    param.startTime = time;
  }

  if (time === RelocateTrailTimeTypeMap.custom) {
    param.startTime = rangeTime[0];
    param.endTime = rangeTime[1];
  }

  // 无论表单哪个值变化，这个时间都要判断&赋值
  if (time === RelocateTrailTimeTypeMap.custom) {
    param.startTime = rangeTime[0];
    param.endTime = rangeTime[1];
  } else {
    param.startTime = time;
    param.endTime = endTimeStr;
  }
  return param;
};

export default () => {
  const [form] = useForm<TopFilterForm>();
  const prevTime = useRef(RelocateTrailTimeTypeMap.threeYears);
  const user = useReactShareUserStore.getState().user;
  const { sendUserEvent } = useUserEvent();
  const { selectableCityList, userDefaultCity, dimension, defaultSubmarketId } =
    useRelocateAnalysisStore(
      useShallow((state) => ({
        selectableCityList: state.selectableCityList,
        userDefaultCity: state.userDefaultCity,
        defaultSubmarketId: state.defaultSubmarketId,
        dimension: state.dimension,
      }))
    );

  const setSelectedCity = useReactShareSelectedStore((state) => state.setSelectedCity);
  const currCommercialAndIndustrialOptions = useMemo(() => {
    const result = [];

    if (user.propertyType.includes(PropertyType.commercial)) {
      result.push(commercialAndIndustrialOptions[0]);
    }
    if (user.propertyType.includes(PropertyType.industrial)) {
      result.push(commercialAndIndustrialOptions[1]);
    }
    return result;
  }, [commercialAndIndustrialOptions]);

  const hasListAuth = useUserAuth('front:relocation:analysis:chart');

  const getFormDefaultValues = (): Partial<any> => {
    const queryParam = {
      city: userDefaultCity,
      time: [
        RelocateTrailTimeTypeMap.threeYears,
        [RelocateTrailTimeTypeMap.threeYears, endTimeStr],
      ],
      mode: RelocateAnalysisModeType.map,
      districtAndSubmarket: {
        submarket: [defaultSubmarketId],
      },
      propertyType: OptionsToDefaultValues(currCommercialAndIndustrialOptions, [0]) as Record<
        string,
        string[]
      >,
    };
    // setTopFormQueryParam(queryParam);
    return queryParam;
  };

  const onFormValueChange = (changedValues: Partial<TopFilterForm>, allValues: TopFilterForm) => {
    if (
      changedValues.time?.[0] === RelocateTrailTimeTypeMap.custom &&
      changedValues.time?.[0] !== prevTime.current
    ) {
      prevTime.current = changedValues.time?.[0];
      return;
    }

    console.log('onFormValueChange', changedValues, allValues);

    if (changedValues.mode) {
      sendUserEvent(
        {
          button: `搬迁分析-点击${changedValues.mode === RelocateAnalysisModeType.map ? '地图' : '图表'}模式`,
        },
        'e_click'
      );
      setDisplayMode(changedValues.mode);
      const param = {
        ...generateQueryParam(allValues),
        dimension,
      };
      queryData(param, changedValues.mode);
      return;
    }

    if (changedValues.districtAndSubmarket) {
      const { submarket } = changedValues.districtAndSubmarket;
      setBoundariesType(submarket ? BoundariesType.submarket : BoundariesType.district);
    }

    const queryParam = {
      ...generateQueryParam(allValues),
      dimension,
    };

    if (changedValues.city) {
      const newSubmarketId = selectableCityList.find(
        (item) => item.value === changedValues.city
      ).defaultSubmarketId;
      form.setFieldValue('districtAndSubmarket', {
        submarket: [newSubmarketId],
      });
      queryParam.subMarketId = newSubmarketId;
      queryParam.district = '';
      setBoundariesType(BoundariesType.submarket);
      queryRelocateAnalysisPageOptions(changedValues.city);
      setSelectedCity(changedValues.city);
    }

    setTopFormQueryParam(queryParam);
    queryData(queryParam);
  };

  const customDropdownRender = (menu: any) => (
    <div>
      {menu}
      <p
        style={{
          color: '#637177',
          fontSize: '12px',
          fontWeight: 400,
          paddingLeft: '10px',
          paddingRight: '10px',
          marginTop: '8px',
          marginBottom: '8px',
        }}
      >
        *目前仅覆盖部分城市，未来将逐步拓展至其他城市，敬请期待
      </p>
    </div>
  );
  return (
    <div>
      <JLLForm
        form={form}
        layout="inline"
        initialValues={getFormDefaultValues()}
        onValuesChange={onFormValueChange}
      >
        <JLLForm.Item
          name="city"
          className={styles.filterFormItem}
        >
          <JLLSelect
            options={selectableCityList}
            dropdownRender={customDropdownRender}
          />
        </JLLForm.Item>

        <JLLForm.Item className={styles.filterFormItem}>
          <DistrictAndSubMarket
            single
            hideRightAll
            searchPlaceholder="请输入分析的目标区域"
          />
        </JLLForm.Item>

        <JLLForm.Item className={styles.filterFormItem}>
          <IndustrialPicker
            placeholder="选择行业类型"
            search
            searchPlaceholder="请输入搬迁分析的目标行业"
          />
        </JLLForm.Item>
        <JLLForm.Item
          name="propertyType"
          className={styles.filterFormItem}
        >
          <JLLCascader
            search
            searchPlaceholder="请输入搬迁分析的物业类型"
            className="headerFormItem"
            placeholder="选择物业类型"
            options={currCommercialAndIndustrialOptions}
          />
        </JLLForm.Item>
        <JLLForm.Item
          name="time"
          style={{ marginLeft: '2px', marginRight: '12px' }}
        >
          <DateTimePicker />
        </JLLForm.Item>
        <ConfigProvider
          theme={{
            components: {
              Radio: radioButtonConfig,
            },
          }}
        >
          <JLLForm.Item
            name="mode"
            style={{ marginLeft: '0', marginRight: 0 }}
            hidden={!hasListAuth}
          >
            <JLLButtonRadio
              options={modeTypeOptions}
              style={{ width: '104px' }}
            />
          </JLLForm.Item>
        </ConfigProvider>
      </JLLForm>
    </div>
  );
};
