import { Tooltip, JLLSvg, JLLButtonRadio } from 'jlld';
import styles from './style.module.less';

export enum SwitchType {
  area = 'area',
  count = 'count',
}

const typeOptions = [
  { label: '面积占比', value: SwitchType.area },
  { label: '个数占比', value: SwitchType.count },
];

const BlockTitle = (props: {
  title: string;
  showSwitch?: boolean;
  switchType: SwitchType;
  onChangeType: (type: SwitchType) => void;
  showTip?: boolean;
  tipText?: string;
}) => {
  return (
    <div className={styles.blockTitle}>
      <div className={styles.titleText}>
        {props.title}
        {props.showTip ? (
          <div className={styles.tipIcon}>
            <Tooltip title={props.tipText}>
              <JLLSvg
                icon="info"
                size={20}
                color="#667579"
              />
            </Tooltip>
          </div>
        ) : null}
      </div>
      {props.showSwitch ? (
        <JLLButtonRadio
          value={props.switchType}
          options={typeOptions}
          className="small"
          style={{ width: '140px' }}
          onChange={(val) => props?.onChangeType(val)}
        />
      ) : null}
    </div>
  );
};

export default BlockTitle;
