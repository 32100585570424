export const commercialLegends = [
  {
    name: '已交付',
    color: '#274EC7',
  },
  {
    name: '未来项目',
    color: 'rgba(39, 78, 199, 0.5)',
  },
];
export const industryLegends = [
  {
    name: '已交付',
    color: '#236075',
  },
  {
    name: '未来项目',
    color: 'rgba(35, 96, 117, 0.5)',
  },
];
