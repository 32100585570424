import { minAndMax } from '@/utils/chart';
import ReactECharts from 'echarts-for-react';
import { ProjectRowData } from '../../types';
import { useMemo, useRef } from 'react';
import Empty from '@/components/Empty/index.tsx';
import { lineStyles } from './config';

export default (props: {
  legend: string[];
  data: ProjectRowData[][];
  unit?: string;
  emptyText?: string;
}) => {
  const { legend, data, unit, emptyText } = props;
  const chartRef = useRef<ReactECharts>(null);

  const hasData = useMemo(() => {
    return data.some(
      (item) =>
        !!item?.filter((item) => item.specificField !== null && item.specificField !== undefined)
          .length
    );
  }, [data]);

  const getOptions = () => {
    const colors = ['#FF838B'];
    const xAxis = [...new Set(data.flat(1).map((item) => item.rowTitle))];

    const series: any[] = [];
    legend.forEach((name, index) => {
      series.push({
        type: 'line',
        name,
        z: 10,
        data: xAxis.map(
          (axis) =>
            data?.[index].filter((item) => item.rowTitle === axis)?.[0]?.specificField ?? '--'
        ),
        symbol: 'emptyCircle', // 拐点设置为空心
        symbolSize: 6,
        ...lineStyles[index],
        emphasis: {
          focus: 'series',
          label: {
            show: true,
            color: '#fff',
            fontSize: '12px',
            fontWeight: 400,
            padding: [4, 8],
            borderRadius: 20,
            backgroundColor: '#01151D',
          },
          scale: true,
          itemStyle: {
            color: colors[0],
            borderWidth: 4,
          },
        },
        blur: {
          itemStyle: {
            opacity: 0.4,
          },
        },
      });
    });
    return {
      color: colors,
      title: {
        show: xAxis.length === 0,
        text: '暂无数据',
        left: 'center',
        top: 'center',
        textStyle: {
          color: '#909399',
          fontSize: 16,
          fontWeight: 400,
        },
      },
      tooltip: {
        trigger: 'axis',
        confine: true,
        formatter: (params: any) => {
          if (chartRef.current) {
            chartRef.current.getEchartsInstance().dispatchAction({
              type: 'downplay',
            });
          }
          const param = params.filter((item: any) => item.value !== '--');
          const [{ axisValue, seriesName, value }] = param.length ? param : params;
          return `<div>
              ${axisValue}<br />
              <span style="display:inline-block;width:10px;height:10px;border-radius: 10px;background: ${colors};"></span>
              <span style="display:inline-block;width:${
                seriesName.length * 12 + 20
              }px">${seriesName}</span>${value}${unit}</div>`;
        },
        axisPointer: {
          type: 'line',
          z: 9,
        },
      },
      legend: {
        show: true,
        top: 15,
        right: 0,
        itemWidth: 22,
        itemHeight: 0,
        data: legend.map((name, index: number) => {
          const {
            lineStyle: { type },
          } = lineStyles[index];
          return {
            name,
            itemStyle: {
              color: 'FF838B',
              borderColor: 'FF838B',
              borderType: type,
              borderWidth: 2,
            },
          };
        }),
        textStyle: {
          color: '#4A4E57',
          fontSize: 12,
        },
      },
      grid: {
        left: 10,
        right: '5%',
        bottom: 5,
        top: 50,
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: xAxis,
          show: xAxis.length !== 0,
          boundaryGap: true,
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: '#525252',
              fontSize: 12,
            },
            align: 'center',
            verticalAlign: 'top',
            margin: 32,
            rotate: 45,
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: '#EDEDED',
            },
          },
          splitLine: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          name: unit,
          show: xAxis.length !== 0,
          axisLabel: {
            textStyle: {
              color: '#525252',
            },
            margin: 20,
          },
          ...minAndMax(series, '--'),
          axisLine: { show: false },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#EBECEC'],
            },
          },
        },
      ],
      series,
    };
  };
  if (!hasData) {
    return <Empty text={emptyText} />;
  }
  return (
    <ReactECharts
      ref={chartRef}
      option={getOptions()}
      notMerge
      lazyUpdate
    />
  );
};
