import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { getLoginBgImage } from '../api';
import { BackgroundImage } from '../types';

interface State {
  backgroundImages: BackgroundImage[];
}

const initState: State = {
  backgroundImages: [],
};

const useBlockTradeStore = create(
  immer(
    combine(initState, (set, get) => ({
      queryBackgroundImage: async () => {
        const res = await getLoginBgImage();
        console.log('result', res.fileList);
        if (res) {
          set((state) => ({
            backgroundImages: res.fileList,
          }));
        }
      },
    }))
  )
);

export default useBlockTradeStore;
