import { useEffect } from 'react';
import classNames from 'classnames';
import { useShallow } from 'zustand/react/shallow';
import useUserEvent from '@/hooks/useUserEvent';
import useLoginStore from './store';
import Background from './components/Background';
import LoginForm from './components/LoginForm';
import styles from './style.module.less';

export default () => {
  const { sendUserEvent } = useUserEvent();

  const { queryBackgroundImage } = useLoginStore(
    useShallow((state) => ({
      queryBackgroundImage: state.queryBackgroundImage,
    }))
  );

  useEffect(() => {
    queryBackgroundImage();
    sendUserEvent({ page: '登陆' }, 'e_view');
  });

  return (
    <div className={classNames('page', styles.login)}>
      <Background />
      <LoginForm />
    </div>
  );
};
