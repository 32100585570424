import ReactECharts from 'echarts-for-react';
import { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { message } from 'jlld';
import { numFormat } from '@/utils/tool';
import { downloadChart } from '@/utils/downloadChart';
import { Empty } from '@/components';
import { generateRentValue, generateRentUnit } from '@/components/UnitExchange/method';
import './style.less';

interface ChartDataItem {
  dynamicColOne: string;
  dynamicColTwo: string;
  dynamicColThree: string;
  areaTotal: string;
  areaTotalNum: string;
  areaProportion: string;
  areaProportionSign: string;
  numTotal: string;
  numProportion: string;
  numProportionSign: string;
}

interface DoubleAxisChartProps {
  chartData: [];
  valueType?: string;
  leftType: string;
  rightType: string;
  checkedLeft: string[];
  checkedRight: string[];
  dataUnitType: string;
}

// 寻找出最合适得等差数列
const workNumberToSplit = (min, max, interval, split) => {
  let minOutput = min;
  let maxOutput = max;
  let intervalOutput = interval;
  for (let i = interval; i++; i < 2 * interval) {
    const minInner = -i;
    const maxInner = i * (split - 1);
    const maxInner2 = i;
    const minInner2 = -i * (split - 1);
    if (minInner <= min && maxInner >= max) {
      minOutput = minInner;
      maxOutput = maxInner;
      intervalOutput = i;
      break;
    }
    if (minInner2 <= min && maxInner2 >= max) {
      minOutput = minInner2;
      maxOutput = maxInner2;
      intervalOutput = i;
      break;
    }
  }

  return [minOutput, maxOutput, intervalOutput];
};

// 图表强制,分5段，切为整数
export const echartSplit = (value: any[], split = 5) => {
  const valueClone = value.filter((i) => i ?? 0);
  const min = valueClone.length > 0 ? Math.floor(Math.min(...valueClone, 0)) : 0; // 刻度最小值
  const max = valueClone.length > 0 ? Math.ceil(Math.max(...valueClone, 1)) : 0; // 刻度最大值
  const interval = Math.max(Math.ceil((max - min) / split), 1);

  if (min < 0 && max > 0) {
    // 有负数 必须要有0值
    const [a, b, c] = workNumberToSplit(min, max, interval, split);

    return {
      min: a,
      max: b,
      interval: c,
      // arr: splitValue(a, split, c),
    };
  }

  const reayMin = min;
  const reayMax = min + interval * split; // 防止分段 出现小数

  if (interval === 0) {
    // 只有一个点
    const minInner = max >= 0 ? 0 : max - 1;
    return {
      min: minInner,
      max: reayMax,
      interval: (max - minInner) / split,
      // arr: splitValue(minInner, split, (max - minInner) / split),
    };
  }

  return {
    min: reayMin,
    max: reayMax,
    interval,
    // arr: splitValue(reayMin, split, interval),
  };
};

const DoubleAxisChart = forwardRef((props: DoubleAxisChartProps, ref) => {
  const doubleAxisChartRef = useRef(null);
  const doubleAxisChartDownloadRef = useRef(null);
  const [chartOption, setChartOption] = useState({});

  const xAxisValue = {
    left: [],
    right: [],
  };

  const getUnit = (val: any) => {
    if (!val) return '';
    let map;
    if (props.dataUnitType === '1') {
      map = new Map([
        ['项目数', '个'],
        ['空置率', '%'],
        ['总存量', '㎡'],
        ['账面租金', generateRentUnit('bookRentUnit')],
        ['新增供应', '㎡'],
        ['有效租金', generateRentUnit('effectiveRentUnit')],
        ['净吸纳量', '㎡'],
        ['物管费', '元/㎡/月'],
      ]);
    } else {
      map = new Map([
        ['项目数', '个'],
        ['空置率', '%'],
        ['总存量', '万㎡'],
        ['账面租金', generateRentUnit('bookRentUnit')],
        ['新增供应', '万㎡'],
        ['有效租金', generateRentUnit('effectiveRentUnit')],
        ['净吸纳量', '万㎡'],
        ['物管费', '元/㎡/月'],
      ]);
    }
    const [name] = val.split('(');
    return map.get(name);
  };

  const getSeriesByType = (params: any) => {
    const { name, type, yAxisIndex } = params;
    const [colorName] = name.split('(');
    const barColorMap = new Map([
      ['新增供应', '#D1B9A7'],
      ['净吸纳量', '#40798D'],
      ['总存量', '#95C6D5'],
      ['项目数', '#955F95'],
      ['空置率', '#A5C7A6'],
      ['物管费', '#7D6F64'],
      ['账面租金', '#AABCF4'],
      ['有效租金', '#4D7D4F'],
    ]);
    const lineColorMap = new Map([
      ['新增供应', '#D1B9A7'],
      ['净吸纳量', '#40798D'],
      ['总存量', '#95C6D5'],
      ['项目数', '#955F95'],
      ['空置率', '#A5C7A6'],
      ['物管费', '#7D6F64'],
      ['账面租金', '#AABCF4'],
      ['有效租金', '#4D7D4F'],
    ]);
    // const lineColor = [...barColor];
    const finalColor = yAxisIndex === 0 ? barColorMap.get(colorName) : lineColorMap.get(colorName);
    if (type === 'bar') {
      return {
        ...params,
        color: finalColor, // barColor[index],
        barWidth: 35,
        barGap: '5%',
        tooltip: {
          valueFormatter: (value: number) => {
            return value;
          },
        },
      };
    }
    if (type === 'line') {
      return {
        ...params,
        color: finalColor, // lineColor[index],
        symbol: 'emptyCircle', // 拐点设置为空心
        symbolSize: 6,
        emphasis: {
          scale: true,
          symbol: 'circle',
          itemStyle: {
            color: finalColor,
            borderWidth: 4,
          },
        },
        tooltip: {
          valueFormatter: (value: number) => {
            return value;
          },
        },
      };
    }
    return {
      ...params,
      type: 'line',
      color: `${finalColor}70`, // `${lineColor[index]}70`,
      symbol: 'emptyCircle', // 拐点设置为空心
      symbolSize: 6,
      itemStyle: {
        color: finalColor,
        borderWidth: 4,
      },
      areaStyle: {},
      tooltip: {
        valueFormatter: (value: number) => {
          return value;
        },
      },
    };
  };

  const optionInner = {
    graphic: {
      type: 'text',
      silent: true,
      left: 'center',
      bottom: 0,
      z: 100,
      style: {
        fill: '#8d949d',
        text: ['powered by JLL睿见数据', 'JLL睿见数据提供技术及数据支持'].join('\n\n'),
        fontSize: 12,
        lineHeight: 12,
        textAlign: 'center',
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      formatter: (params: any) => {
        const ctxTmp = params
          .map(
            (it: any) => `
        <div>
          <i style="background: ${
            it.color
          };display:inline-block;width:12px;height:12px;margin-right:8px;"></i>
          <span style="display:inline-block;width:120px;color: #60666E;font-size: 14px">${
            it.seriesName
          }</span>
          <span style="color: #303133;">${
            numFormat(it.value) || (it.seriesName.indexOf('新增供应') === 0 ? '0' : '--')
          }${getUnit(it.seriesName)}</span>
        </div>      
        `
          )
          .join('');
        const temp = `
        <div>
          ${ctxTmp}
        </div>
        `;
        return temp;
      },
    },
    grid: {
      // show: true,
      top: 80,
      left: 100,
      right: 100,
      bottom: 100,
    },
    legend: {
      show: true,
      top: 10,
    },
    xAxis: [
      {
        type: 'category',
        axisTick: false,
        data: [],
        axisPointer: {
          type: 'shadow',
        },
        axisLabel: {
          show: true,
          interval: 0,
        },
        axisLine: {
          // show: false,
          onZero: false,
          lineStyle: {
            color: '#525252',
            type: 'solid',
          },
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: '㎡',
        show: true,
        axisLabel: {
          formatter: '{value}',
        },
      },
      {
        type: 'value',
        name: '元/㎡/月',
        show: true,
        axisLabel: {
          formatter: '{value}',
        },
        // splitLine: {
        //   show: false,
        // },
      },
    ],
    series: [],
  };

  const initOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      formatter: (params) => {
        const ctxTmp = params
          .map(
            (it) => `
        <div>
          <i style="background: ${
            it.color
          };display:inline-block;width:12px;height:12px;margin-right:8px;"></i>
          <span style="display:inline-block;width:120px;color: #60666E;font-size: 14px">${
            it.seriesName
          }</span>
          <span style="color: #303133;">${
            numFormat(it.value) || (it.seriesName.indexOf('新增供应') === 0 ? '0' : '--')
          }${getUnit(it.seriesName)}</span>
        </div>      
        `
          )
          .join('');
        const temp = `
        <div>
          ${ctxTmp}
        </div>
        `;
        return temp;
      },
    },
    grid: {
      // show: true,
      top: 10,
      left: 0,
      right: 0,
    },
    legend: {
      show: false,
      top: 0,
    },
    xAxis: [
      {
        type: 'category',
        axisTick: false,
        data: [],
        axisPointer: {
          type: 'shadow',
        },
        axisLine: {
          // show: false,
          onZero: false,
          lineStyle: {
            color: '#525252',
            type: 'solid',
          },
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: '㎡',
        show: true,
        axisLabel: {
          formatter: '{value}',
        },
      },
      {
        type: 'value',
        name: '元/㎡/月',
        show: true,
        axisLabel: {
          formatter: '{value}',
        },
        // splitLine: {
        //   show: false,
        // },
      },
    ],
    series: [],
  };

  const echartDoubleYSplit = (values: any) => {
    let split = 3;
    if (Math.min(...values[0]) >= 0 && Math.min(...values[1]) >= 0) {
      split = 5;
    }
    const a = echartSplit(values[0], split);
    const b = echartSplit(values[1], split);
    const up0 = a.max / a.interval; // 左边 0上轴
    const up1 = b.max / b.interval; // 右边 0 上轴
    const down0 = a.min / a.interval; // 左边 0下轴
    const down1 = b.min / b.interval; // 右边 0 下轴
    let maxUp = Math.max(up0, up1); // 最大0上轴
    const maxDown = Math.min(down0, down1); // 最大0上轴
    if (Math.abs(maxDown) + Math.abs(maxUp) < 5) {
      // 分段太少的话，还是保持5个分段
      maxUp = 5 - Math.abs(maxDown);
    }
    a.max = maxUp * a.interval;
    b.max = maxUp * b.interval;
    a.min = maxDown * a.interval;
    b.min = maxDown * b.interval;
    return [a, b];
  };

  const getSeries = (dt, yIdx = 0, isShow = true) => {
    const arr = dt?.map((item) => {
      optionInner.xAxis[0].data = item.pointVOS.map((it: any) => it.rowTitle);
      const type = yIdx === 0 ? props.leftType : props.rightType;
      // const name = item.charHead;
      const name = yIdx === 0 ? `${item.charHead}(左轴)` : `${item.charHead}(右轴)`;
      let renderData;
      switch (item.charHead) {
        case '账面租金': {
          renderData = item.pointVOS.map((it: any) =>
            generateRentValue(it.specificField, 'bookRentUnit', true)
          );
          break;
        }
        case '有效租金': {
          renderData = item.pointVOS.map((it: any) =>
            generateRentValue(it.specificField, 'effectiveRentUnit', true)
          );
          break;
        }
        default: {
          renderData = item.pointVOS.map((it: any) => it.specificField);
        }
      }

      return getSeriesByType({
        type,
        name,
        data: renderData,
        yAxisIndex: yIdx,
        itemStyle: !isShow ? { color: 'transparent' } : {},
      });
    });
    return arr;
  };

  const getNewChartOption = (d: ChartDataItem[], type = 'areaProportion') => {
    xAxisValue.left = [];
    xAxisValue.right = [];
    const left = props.chartData?.filter((item) => {
      return props.checkedLeft.includes(item.charHead);
    });
    const right = props.chartData?.filter((item) => props.checkedRight.includes(item.charHead));
    const seriesL = getSeries(left, 0, true) || [];
    const seriesR = getSeries(right, 1, true) || [];
    const leftName = props.checkedLeft.filter((it) => it !== '无指标');
    const rightName = props.checkedRight.filter((it) => it !== '无指标');
    const yUnitL = getUnit(leftName[0]);
    const yUnitR = getUnit(rightName[0]);
    // const yUnitL = '个';
    // const yUnitR = '个';
    initOption.yAxis[0].name = yUnitL;
    initOption.yAxis[1].name = yUnitR;
    seriesL?.forEach((it: any) => {
      xAxisValue.left.push(...it.data);
    });
    seriesR?.forEach((it: any) => {
      xAxisValue.right.push(...it.data);
    });
    const [leftY, rightY] = echartDoubleYSplit([xAxisValue.left, xAxisValue.right]);
    optionInner.yAxis[0] = { ...initOption.yAxis[0], ...leftY };
    optionInner.yAxis[1] = { ...initOption.yAxis[1], ...rightY };
    optionInner.series = [...seriesL, ...seriesR];
    const dataLength = props?.chartData ? props?.chartData[0]?.pointVOS?.length : 0;

    optionInner.dataZoom =
      dataLength > 10
        ? [
            {
              show: true,
              type: 'slider',
              orient: 'horizontal',
              start: 0,
              bottom: 45,
              end: Math.floor((10 / dataLength) * 100),
              height: 12,
              backgroundColor: 'transparent',
              fillerColor: 'rgba(0, 0, 0, 0.2)',
              filterMode: 'none',
              zoomLock: true,
              zoomOnMouseWheel: false,
              moveOnMouseMove: true,
              throttle: 100,
              showDetail: false,
              brushSelect: false,
              dataBackground: {
                lineStyle: {
                  opacity: 0,
                },
                areaStyle: {
                  opacity: 0,
                },
              },
              selectedDataBackground: {
                lineStyle: {
                  opacity: 0,
                },
                areaStyle: {
                  opacity: 0,
                },
                brushStyle: {
                  borderColor: '#f00',
                },
              },
            },
          ]
        : [];
    setChartOption({ ...optionInner });
  };

  const downloadChartImg = () => {
    if (props.chartData.length === 0) {
      message.error('图表暂无数据，不可下载！');
      return;
    }
    downloadChart(doubleAxisChartDownloadRef, `${Date.now()}市场表现`);
  };

  useImperativeHandle(ref, () => ({
    downloadChartImg,
  }));

  useEffect(() => {
    getNewChartOption(props.chartData);
  }, [
    props.chartData,
    props.valueType,
    props.checkedLeft,
    props.checkedRight,
    props.leftType,
    props.rightType,
  ]);

  return (
    <>
      {props.chartData?.length > 0 ? (
        <ReactECharts
          style={{
            height: `${document.getElementById('CommonTableWrapContent')?.clientHeight - 40}px`,
          }}
          ref={doubleAxisChartRef}
          option={chartOption}
          notMerge={true}
          lazyUpdate={true}
        />
      ) : (
        <Empty />
      )}
      {props.chartData?.length > 0 ? (
        <div className="chartDownloadWrap">
          <ReactECharts
            style={{
              height: '100%',
              width: `${
                props.chartData[0]?.pointVOS?.length > 6
                  ? props.chartData[0]?.pointVOS?.length * 300
                  : 1500
              }px`,
            }}
            ref={doubleAxisChartDownloadRef}
            option={{ ...chartOption, dataZoom: [] }}
            notMerge={true}
            lazyUpdate={true}
          />
        </div>
      ) : null}
    </>
  );
});

export default DoubleAxisChart;
