import styles from './style.module.less';
import { JLLForm, Button, JLLSvg } from 'jlld';
import classNames from 'classnames';
import { useState } from 'react';
import { FormInstance } from 'jlld/es';
import useCommonOptionsStore, { queryDistrictSubmarkets } from '@/store/commonOptions';
import useUserEvent from '@/hooks/useUserEvent';
import useReactShareSelectedStore, { useCity } from '@/store/reactShareSelectedStore';
import FormLoadingSkeletion from './FormLoadingSkeletion';

export interface DataAnalysisTopFormProps<T> {
  tabModuleName?: string;
  form: FormInstance<T>;
  children?: JSX.Element | JSX.Element[];
  footer?: JSX.Element | JSX.Element[];
  switch?: JSX.Element;
  initialValues?: T;
  initValues?: T;
  loading?: boolean;
  onReset?: (values: T) => void;
  onSubmit?: (values: T) => void;
  onValuesChange?: (values: T) => void;
}

function DataAnalysisTopForm<T = Record<string, any>>(props: DataAnalysisTopFormProps<T>) {
  const [showMore, setShowMore] = useState(false);
  const city = useCity();
  const setSelectedCity = useReactShareSelectedStore((state) => state.setSelectedCity);
  const loading = useCommonOptionsStore((state) => state.loading);
  const { sendUserEvent } = useUserEvent();

  const { form, children, footer, initialValues, initValues } = props;

  const onReset = () => {
    const values = { citys: [city], ...initValues };
    const keys = [...Object.keys(values), 'dateValue', 'analysisTime', 'analyzeEndTime'];
    form.setFields(
      Object.entries({ ...initialValues, ...values }).map(([name, value]) => {
        return { name, value: keys.includes(name) ? value : undefined };
      })
    );
    props.onReset?.(form.getFieldsValue());
  };

  const onSubmit = () => {
    props.onSubmit?.(form.getFieldsValue());
  };

  if (props.loading || loading.dataAnalysis) {
    return <FormLoadingSkeletion />;
  }
  return (
    <div className={styles.topFormsWrap}>
      <JLLForm
        initialValues={initialValues as Record<string, any>}
        form={form}
        onValuesChange={async (value) => {
          if (value.citys) {
            if (value.citys.length > 0) {
              setSelectedCity(value.citys[0]);
              form.setFieldValue('citys', value.citys);
              queryDistrictSubmarkets(value.citys);
            } else {
              form.setFieldValue('districtsSubmarkets', undefined);
            }
          }
          props.onValuesChange?.(value);
        }}
      >
        <div className={classNames(styles.topFormAndSwitch, { [styles.show]: showMore })}>
          <div
            className={styles.topForm}
            style={{ width: `calc(100% - ${props.footer ? '234px' : '148px'})` }}
          >
            {children}
          </div>
          {props.switch}
        </div>
        <div
          className={classNames(styles.topFormFooter, styles.hidden, {
            [styles.showMore]: showMore && footer,
          })}
        >
          {footer}
        </div>
      </JLLForm>
      <div className={styles.controllers}>
        {footer && (
          <Button
            type="text"
            onClick={() => {
              sendUserEvent(
                { button: `${props.tabModuleName}${showMore ? '收起' : '展开'}筛选条件` },
                'e_click'
              );
              setShowMore(!showMore);
            }}
          >
            <JLLSvg icon={showMore ? 'keyboard-double-arrow-up' : 'keyboard-double-arrow-down'} />
            <span>{showMore ? '收起' : '展开'}</span>
          </Button>
        )}
        <Button
          type="text"
          style={{ margin: '0 12px' }}
          onClick={onReset}
        >
          重置
        </Button>
        <Button
          type="primary"
          onClick={() => {
            sendUserEvent({ button: `${props.tabModuleName}搜索按钮点击` }, 'e_click');
            onSubmit();
          }}
        >
          查询
        </Button>
      </div>
    </div>
  );
}

export default DataAnalysisTopForm;
