import classNames from 'classnames';
import { useEffect, useState } from 'react';
import CircleSVG from '@/components/CircleSVG';
import useRelocateTrailStore from '../../../store';
import { RelocateTrailMapProperty } from '../../../../../types/apiTypes';
import PropertyInfoCard from '../PropertyInfoCard';
import { Marker } from '@uiw/react-amap-marker';
import AutoFitInfoWindow from 'jlld/es/JLLMap/AutoFitInfoWindow';
import { Position } from 'jlld/es/JLLMap/types';
import styles from './style.module.less';

export interface PathItem {
  areaName?: string;
  name?: string;
  path: Position[] | Position[][];
  center: Position;
  isEmpty: boolean;
  current?: boolean;
  top3?: boolean;
  otherSource?: boolean;
  tenantNum: string;
  selfTenantNum: number;
  otherTenantNum: number;
  otherSourceTenantNum: number;
}

const getCircleData = (item: PathItem) => {
  const { selfTenantNum, otherTenantNum, otherSourceTenantNum } = item;
  const total = (selfTenantNum || 0) + (otherTenantNum || 0) + (otherSourceTenantNum || 0);
  const result = [];
  if (selfTenantNum) {
    result.push({
      color: '#D1B9A7',
      percent: selfTenantNum / total,
    });
  }
  if (otherTenantNum) {
    result.push({
      color: '#40798D',
      percent: otherTenantNum / total,
    });
  }
  if (otherSourceTenantNum) {
    result.push({
      color: '#D4D4D4',
      percent: otherSourceTenantNum / total,
    });
  }
  return result;
};

export default () => {
  const trailMapData = useRelocateTrailStore((state) => state.trailMapData);
  const dimension = useRelocateTrailStore((state) => state.dimension);

  const [activeItem, setActiveItem] = useState<RelocateTrailMapProperty>(
    {} as RelocateTrailMapProperty
  );
  const [isCurrent, setIsCurrent] = useState(false);
  const [isOtherSource, setIsOtherSource] = useState(false);
  const [propertyId, setPropertyId] = useState('');

  const [infoCardVisiable, setInfoCardVisiable] = useState(false);
  const [hoverPropertyId, setHoverPropertyId] = useState('');

  useEffect(() => {
    // TODO: 在切换事件隐藏
    setInfoCardVisiable(false);
  }, [dimension]);

  const clickMark = (item: RelocateTrailMapProperty, current: boolean) => {
    setIsOtherSource(!!item.isOtherSource);
    setIsCurrent(current);
    setPropertyId(item.propertyId);
    setActiveItem(item);
    setInfoCardVisiable(true);
  };

  const onInfoCardClose = () => {
    setActiveItem({} as RelocateTrailMapProperty);
    setInfoCardVisiable(false);
  };
  const renderPropertyMakers = () => {
    return (
      <>
        {trailMapData?.otherProperty?.map((item, index) => {
          return (
            <Marker
              key={index}
              position={item?.center}
              anchor="center"
              onMouseOver={() => {
                setHoverPropertyId(item.isOtherSource ? item.propertyAddress : item.propertyId);
              }}
              onMouseOut={() => {
                setHoverPropertyId('');
              }}
              onClick={() => clickMark(item, false)}
              visiable
              zIndex={item.isOtherSource ? 100 : 500}
            >
              <div className={styles.propertyMarkerWrap}>
                <div
                  className={classNames(styles.propertyMarker, styles.otherProperty, {
                    [styles.otherSource]: item.isOtherSource,
                    [styles.active]: item.isOtherSource
                      ? item.propertyAddress === activeItem.propertyAddress
                      : item.propertyId === activeItem.propertyId,
                  })}
                >
                  {item.tenantNum}
                </div>
                {item.isOtherSource ? null : (
                  <div
                    className={classNames(styles.markName, {
                      [styles.active]: item.propertyId === hoverPropertyId,
                    })}
                  >
                    {item.propertyName}
                  </div>
                )}
              </div>
            </Marker>
          );
        })}

        <Marker
          position={trailMapData?.curProperty?.center}
          anchor="center"
          onClick={() => clickMark(trailMapData?.curProperty, true)}
          visiable={true}
          onMouseOver={() => {
            setHoverPropertyId(trailMapData?.curProperty?.propertyId);
          }}
          onMouseOut={() => {
            setHoverPropertyId('');
          }}
          zIndex={1101}
        >
          <div className={styles.propertyMarkerWrap}>
            <div className={classNames(styles.propertyMarker, styles.currentProperty)}>
              <div className={styles.currentCircle}>
                <CircleSVG
                  data={getCircleData(trailMapData?.curProperty)}
                  strokeWidth={4}
                  radius={42}
                  value={trailMapData?.curProperty?.tenantNum}
                  active={trailMapData?.curProperty?.propertyId === hoverPropertyId}
                />
              </div>
            </div>
            {trailMapData?.curProperty?.propertyId === hoverPropertyId ? (
              <div className={classNames(styles.markName, styles.active, styles.current)}>
                {trailMapData?.curProperty?.propertyName}
              </div>
            ) : null}
          </div>
        </Marker>
      </>
    );
  };

  return (
    <>
      <AutoFitInfoWindow
        position={activeItem.center}
        arrowStyle={{ backgroundColor: '#fff' }}
        visiable={infoCardVisiable}
      >
        <PropertyInfoCard
          isCurrent={isCurrent}
          propertyId={propertyId}
          onClose={onInfoCardClose}
          isOtherSource={isOtherSource}
          itemData={activeItem}
        />
      </AutoFitInfoWindow>

      {renderPropertyMakers()}
    </>
  );
};
