import JSEncrypt from 'jsencrypt/bin/jsencrypt.min';

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC1rm2Ikv7g0keV9aMiM/qIWam3HXumsHrBNKWPiJZXnkYgx3Hgz2nuJjQOCO4Ofjrhgy77dkCaWBLZAqBUOCGknoM9zJ10TKSN
6xj6k8wm6FSju5OVGWwDrypgCAFfF2Kr2ZGFITeAs1ZTGownRAlK3XGyXQOMiR51NQlbYXYe9wIDAQAB`;

// 加密
// eslint-disable-next-line import/prefer-default-export
export function encrypt(txt) {
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKey); // 设置公钥
  return encryptor.encrypt(txt); // 对数据进行加密
}
