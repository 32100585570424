import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { ShowDataTabType } from '@/pages/F-DataAnalysis/config';
import {
  generateCommonTableData,
  generateQueryParams,
  topFormValuesToParams,
} from '@/pages/F-DataAnalysis/method';
import { AllLoading, TableDataItem } from '@/pages/F-DataAnalysis/types/settingTypes';
import { getFutureSupplyDetailRes } from '../../D-FutureSupply/api';
import { getIndustryDealData } from '@/pages/F-DataAnalysis/api';
import useIndustrySettingStore from './settingStore';
import { deepClone } from '@/utils/tool';

type State = {
  loading: AllLoading;
  tableData: {
    data: Record<string, TableDataItem[]> | null;
    summaryData: TableDataItem | null;
  } | null;
  chartData: [];
  detailData: getFutureSupplyDetailRes | null;
};

const state: State = {
  loading: { topForm: true, table: true, chart: true, detail: true },
  tableData: null,
  chartData: [],
  detailData: null,
};

const useIndustryAnalysisStore = create<State>()(immer(() => state));

const set = useIndustryAnalysisStore.setState;
// const get = useDataAnalysisSettingStore.getState;

export const resetDataAnalysisPageStore = () => {
  set(deepClone(state));
};

export const queryIndustryTable = async () => {
  const { topFormValues, dimensionValues, tableFormValues } = useIndustrySettingStore.getState();
  const queryParams = generateQueryParams(topFormValues, dimensionValues, tableFormValues);
  set((state) => {
    state.loading[ShowDataTabType.table] = true;
  });
  if (queryParams) {
    const result = await getIndustryDealData({ ...queryParams, leaseType: '1' });
    set((state) => {
      const { data, summaryData } = generateCommonTableData(result);

      state.tableData = {
        data,
        summaryData,
      };
      state.loading[ShowDataTabType.table] = false;
    });
  } else {
    set((state) => {
      state.loading[ShowDataTabType.table] = false;
    });
  }
};

export const queryIndustryAnalysisChart = async () => {
  const { topFormValues, chartSettingFormValues } = useIndustrySettingStore.getState();
  const queryParams = topFormValuesToParams(topFormValues);
  set((state) => {
    state.loading[ShowDataTabType.chart] = true;
  });
  if (queryParams) {
    const temp = {};
    if (queryParams.deliveryTime) {
      temp['deliveryStartTime'] = queryParams.deliveryTime[0];
      temp['deliveryEndTime'] = queryParams.deliveryTime[1];
    }
    const chartData = await getIndustryDealData({
      ...queryParams,
      ...chartSettingFormValues,
      summaryType: [],
      dataUnitType: '1',
      // analyzeStartTime,
      // analyzeEndTime: queryParams.analysisTime,
      analyzeType: 1,
      analyzeTwoRowHead: '',
      analyzeThreeRowHead: '',
      ...temp,
      leaseType: '1',
    });
    set((state) => {
      state.chartData = chartData;
      state.loading[ShowDataTabType.chart] = false;
    });
  } else {
    set((state) => {
      state.loading[ShowDataTabType.chart] = false;
    });
  }
};

export const queryCurrentTabData = async () => {
  const { tableActiveTab } = useIndustrySettingStore.getState();
  switch (tableActiveTab) {
    case ShowDataTabType.table: {
      queryIndustryTable();
      break;
    }
    case ShowDataTabType.chart: {
      queryIndustryAnalysisChart();
      break;
    }
  }
};

export const setLoading = (key: keyof AllLoading, loading: boolean) => {
  set((state) => {
    state.loading[key] = loading;
  });
};

export default useIndustryAnalysisStore;
