import classNames from 'classnames';

import { Checkbox, Input, JLLForm, message } from 'jlld';
import { useForm } from 'jlld/es/form/Form';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { encrypt } from '@/utils/comJsencrypt';
import useMainFrameContext from '@/hooks/useMainFrameContext';
import { setToken, setCookieName, removeToken } from '@/utils/auth';
import { login } from '../../api';
import styles from './style.module.less';
import './style.less';

export default () => {
  const {
    data: { router },
  } = useMainFrameContext();
  const [form] = useForm();
  const [userAgreementChecked, setUserAgreementChecked] = useState(true);
  const [thirdPartyChecked, setThirdPartyChecked] = useState(true);

  const goUserAgreement = () => {
    window.open(`${process.env.VUE_APP_PORTAL}/user?fromMember=1`, '_blank');
  };

  const goPrivacyPolicy = () => {
    window.open(`${process.env.VUE_APP_PORTAL}/privacy?fromMember=1`, '_blank');
  };

  const goInfoShare = () => {
    window.open(`${process.env.VUE_APP_PORTAL}/infoShare?fromMember=1`, '_blank');
  };

  const handleLogin = () => {
    form.validateFields().then(
      async (values) => {
        const { username, password } = values;

        console.log('通过校验！', values, password.length, password, encrypt(password.trim()));
        if (!userAgreementChecked) {
          message.warning('请先同意用户协议');
          return;
        }
        if (!thirdPartyChecked) {
          message.warning('请阅读并同意 第三方信息共享说明');
          return;
        }
        const tokenRes = await login({
          username: username.trim(),
          password: encrypt(password.trim()),
        });
        console.log('tokenRes', tokenRes);
        if (!tokenRes) {
          message.error(tokenRes.errorMessage);
          return;
        }
        window.resetResetStore();
        // useUserSelectStore().resetStore();
        removeToken();
        setToken(tokenRes.token);
        setCookieName(username.trim());
        sessionStorage.setItem('getInfoSource', 'login');
        router.push('/');
      },
      () => {
        message.warning('请填写登录信息');
      }
    );

    // this.$refs.loginForm.validate(async (valid) => {
    //   if (valid) {
    //     if (this.rememberMe) {
    //       Cookies.set('username', this.loginForm.username, { expires: 30 });
    //       Cookies.set('password', encrypt(this.loginForm.password), {
    //         expires: 30,
    //       });
    //       Cookies.set('rememberMe', this.rememberMe, {
    //         expires: 30,
    //       });
    //     } else {
    //       Cookies.remove('username');
    //       Cookies.remove('password');
    //       Cookies.remove('rememberMe');
    //     }
    //     if (!this.agree) {
    //       return;
    //     }
    //     if (!this.agreeShare) {
    //       this.shareMsg = '请阅读并同意 第三方信息共享说明';
    //       return;
    //     }
    //     const tokenRes = await login(this.loginForm.username.trim(), this.loginForm.password);
    //     // console.log(tokenRes);
    //     if (!tokenRes.success) {
    //       this.errorMsg = tokenRes.errorMessage;
    //       return;
    //     }
    //     window.resetResetStore();
    //     useUserSelectStore().resetStore();
    //     removeToken();
    //     setToken(tokenRes.data.token);
    //     setCookieName(this.loginForm.username.trim());
    //     sessionStorage.setItem('getInfoSource', 'login');
    //     this.$router.push('/');
    //   }
    // });
  };

  return (
    <div className={styles.loginForm}>
      <img
        className={styles.loginLogo}
        src="/assets/login_logo.svg"
      />
      <p className={styles.title}>欢迎来到睿见数据</p>
      <JLLForm form={form}>
        <JLLForm.Item
          name="username"
          className={styles.loginFormItem}
          rules={[{ required: true, message: '请输入用户名' }]}
        >
          <Input
            placeholder="请输入用户名"
            className={styles.loginInput}
          />
        </JLLForm.Item>
        <JLLForm.Item
          name="password"
          className={styles.loginFormItem}
          rules={[{ required: true, message: '请输入密码' }]}
        >
          <Input.Password
            placeholder="请输入密码"
            className={classNames(styles.loginInput, styles.password)}
            iconRender={(visible) =>
              visible ? <img src="/assets/show_pwd.svg" /> : <img src="/assets/hide_pwd.svg" />
            }
          />
        </JLLForm.Item>
      </JLLForm>

      <div className={styles.operationItem}>
        <Checkbox />
        <p className={styles.optionText}>记住密码</p>
        <p className={classNames(styles.highlight, styles.forget)}>忘记密码</p>
      </div>
      <div className={styles.operationItem}>
        <Checkbox
          checked={userAgreementChecked}
          onChange={() => {
            setUserAgreementChecked(!userAgreementChecked);
          }}
        />
        <p className={styles.optionText}>
          登录即为同意
          <span
            className={styles.highlight}
            onClick={goUserAgreement}
          >
            《用户协议》
          </span>
          <span
            className={styles.highlight}
            onClick={goPrivacyPolicy}
          >
            《隐私政策》
          </span>
        </p>
      </div>
      <div className={styles.operationItem}>
        <Checkbox
          checked={thirdPartyChecked}
          onChange={() => {
            setThirdPartyChecked(!thirdPartyChecked);
          }}
        />
        <p className={styles.optionText}>
          我已确认阅读并同意
          <span
            className={styles.highlight}
            onClick={goInfoShare}
          >
            {' '}
            第三方信息共享说明
          </span>
        </p>
      </div>

      <div
        className={styles.loginButton}
        onClick={handleLogin}
      >
        登录
      </div>
    </div>
  );
};
