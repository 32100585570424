import { useState } from 'react';
import { Radio, JLLSvg, Checkbox, Tooltip, ConfigProvider, JLLButtonRadio } from 'jlld';
import classNames from 'classnames';
import { downloadSheet } from '@/utils/xlsx';
import useUserEvent from '@/hooks/useUserEvent';
import { formatDownloadNumber } from '@/utils/tool';
import AuthWrapWithTooltip from '@/components/AuthWrapWithTooltip';
import useTradeStatisticsStore from '../../../store/tradeStatisticsStore';
import useLeftFormStore from '../../../store/leftFormStore';
import styles from '../style.module.less';
import outerStyles from '../../../style.module.less';
import useReactShareAuthStore from '@/store/reactShareAuthStore';

enum StatisticsType {
  time = '1',
  city = '2',
  cityDeduplicate = '3',
}

const TopForm = () => {
  const { auth } = useReactShareAuthStore();
  const [statisticsType, setStatisticsType] = useState('1');
  const [multiCityCheck, setMultiCityCheck] = useState(false);
  const { sendUserEvent } = useUserEvent();

  const {
    statisticsData,
    setStatisticsQueryType,
    queryStatisticsDownloadList,
    statisticsOrder,
    setStatisticsOrder,
    setCurrent,
  } = useTradeStatisticsStore((state: any) => state);

  const { leftFormQueryParams } = useLeftFormStore((state: any) => state);

  const optionsWithDisabled = [
    { label: '按交易时间统计', value: StatisticsType.time },
    { label: '按城市统计', value: StatisticsType.city },
  ];

  const statisticsTypeChange = (val: any) => {
    setMultiCityCheck(false);
    setCurrent(1);
    if (val === StatisticsType.time) {
      sendUserEvent({ button: '统计维度-时间' }, 'e_click');
      setStatisticsOrder([
        {
          asc: false,
          column: 'statisticsName',
        },
      ]);
    } else if (val === StatisticsType.city) {
      sendUserEvent({ button: '统计维度-城市' }, 'e_click');
      setStatisticsOrder([
        {
          asc: true,
          column: 'count',
        },
      ]);
    }
    setStatisticsType(val);
    setStatisticsQueryType(val);
  };

  const multiCityCheckChange = (val: any) => {
    setMultiCityCheck(val.target.checked);
    sendUserEvent(
      { button: val.target.checked ? '计算口径-仅统单城市交易' : '计算口径-统计所有项目' },
      'e_click'
    );
    if (val.target.checked) {
      setStatisticsQueryType(StatisticsType.cityDeduplicate);
    } else {
      setStatisticsQueryType(statisticsType);
    }
  };

  const downloadExcel = async () => {
    if (!(statisticsData.records?.length > 0)) return;
    sendUserEvent({ button: '交易统计-下载表格' }, 'e_click');
    const res = await queryStatisticsDownloadList({
      ...{
        statisticsType: multiCityCheck ? StatisticsType.cityDeduplicate : statisticsType,
        orders: statisticsOrder,
        current: 1,
        size: 100000,
      },
      ...leftFormQueryParams,
    });
    downloadSheet({
      fileName: '睿见数据-投资交易-交易统计',
      header: [
        statisticsType === StatisticsType.time ? '时间' : '城市',
        '交易个数',
        '交易面积(万㎡)',
        '交易金额(万元)',
        '交易单价(万元/㎡)',
      ],
      dataList: res.records?.map((item: any) => [
        item.statisticsName,
        formatDownloadNumber(item.count),
        formatDownloadNumber(item.saleArea),
        formatDownloadNumber(item.totalPrice),
        formatDownloadNumber(item.unitPrice),
      ]),
      cols: [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }],
    });
  };

  const renderTooltip = () => {
    return (
      <div>
        <p>投资交易存在包含多项目，项目分布在不同城市，计数口径支持2种方式:</p>
        <p>未剔除多城市交易：不同城市包含同一交易时会分别计算；</p>
        <p>剔除多城市交易：统计中会剔除多项目城市的交易不参与统计</p>
      </div>
    );
  };

  return (
    <div className={styles.topForm}>
      <div className={styles.queryWrapper}>
        <ConfigProvider
          theme={{
            components: {
              Radio: {
                buttonColor: '#606266',
                buttonSolidCheckedBg: '#f6f7f7',
                buttonSolidCheckedHoverBg: '#f6f7f7',
                buttonCheckedColorDisabled: 'rgb(152, 152, 152)',
                buttonSolidCheckedActiveBg: '#01151D',
                colorBorder: 'rgb(238, 239, 240)',
                colorPrimaryBorder: 'rgb(238, 239, 240)',
                colorPrimary: 'rgb(1, 21, 28)',
                colorPrimaryHover: 'rgb(238, 239, 240)',
                colorPrimaryActive: 'rgb(1, 21, 28)',
                buttonSolidCheckedColor: '#01151D',
              },
            },
          }}
        >
          <JLLButtonRadio
            style={{ width: '260px' }}
            value={statisticsType}
            options={optionsWithDisabled}
            onChange={statisticsTypeChange}
          />

          {statisticsType === '2' ? (
            <>
              <Checkbox
                className={styles.cityCheck}
                onChange={multiCityCheckChange}
              >
                剔除多城市交易
              </Checkbox>
              <Tooltip
                placement="right"
                title={renderTooltip}
              >
                <JLLSvg
                  icon="help"
                  size={18}
                  color="#667579"
                />
              </Tooltip>
            </>
          ) : null}
        </ConfigProvider>
      </div>
      <AuthWrapWithTooltip
        type="icon-button"
        auth={'front:blocktrade:statistics:download'}
        buttonText="下载表格"
        icon="download"
        iconSize={20}
        className={classNames(
          outerStyles.download,
          statisticsData.records?.length > 0 ? '' : outerStyles.downloadDisabled
        )}
        align={{ offset: [-20, -10] }}
        disabled={statisticsData.records?.length <= 0}
        onClick={downloadExcel}
      />
    </div>
  );
};

export default TopForm;
