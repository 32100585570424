import { OptionItem } from 'jlld/es/JLLSelect';
import { useState } from 'react';
import { Button, JLLSvg, JLLButtonRadio } from 'jlld';
import classNames from 'classnames';
import useUserEvent from '@/hooks/useUserEvent';
import { PageWithWatermark, AuthWrapWithTooltip } from '@/components';
import { TransferOptionItem } from '@/components/JLLTransfer/JLLTransfer';
import { tableSettingValues } from '../../types/settingTypes';
import { DataStateType, ShowDataTabType } from '../../config';
import styles from './style.module.less';
import DimensionTransferModal from '../DimensionTransferModal';
import TableControlModal from '../TableControlModal';
import { MultiOptionItem } from 'jlld/es/JLLMultiSelector';

interface CommonTableWrapProps {
  tabModuleName: string;
  tabOptions: OptionItem[];
  dismensionList: TransferOptionItem[];
  dataIndicatorOptions: MultiOptionItem[];
  dimensionValues: DataStateType[];
  tableFormValues: tableSettingValues;
  children: React.ReactNode;
  customControl?: React.ReactNode;
  activeTab?: ShowDataTabType;
  loading?: boolean;
  tableLoading?: boolean;
  chartLoading?: boolean;
  detailLoading?: boolean;
  downloadAuth?: string;
  detailAuth?: string;
  indicatorsRequired?: boolean;
  onDownloadTable?: () => void;
  onTabChange: (tabName: ShowDataTabType) => void;
  onDimensionChange: (values: DataStateType[]) => void;
  onTableColumnChange: (values: tableSettingValues) => void;
}

const Tabs = {
  table: '列表',
  chart: '图表',
  detail: '明细',
};

const CommonTableWrap = (props: CommonTableWrapProps) => {
  const [dimensionTransferOpen, setDimensionTransferOpen] = useState(false);
  const [tableControlOpen, setTableControlOpen] = useState(false);
  const { sendUserEvent } = useUserEvent();
  const [currentTab, setCurrentTab] = useState<ShowDataTabType>(props.activeTab as ShowDataTabType);
  // let content = null;
  // console.log(props.loading, props.activeTab);
  if (props.loading) {
    // topform没loading好这里不能加载，可以加一个loading效果
    return null;
  }

  const downloadClick = () => {
    sendUserEvent({ button: `${props.tabModuleName}${Tabs[currentTab]}下载` }, 'e_click');
    props.onDownloadTable?.();
  };

  const getLoading = () => {
    if (currentTab === 'chart') {
      return props.chartLoading;
    } else if (currentTab === 'table') {
      return props.tableLoading;
    }
    return props.detailLoading;
  };

  return (
    <div className={styles.commonTableWrap}>
      <div className={styles.operationsContainer}>
        <div className={classNames(styles.tabsContainer)}>
          <JLLButtonRadio
            value={currentTab}
            style={{ width: `${props.tabOptions.length * 60}px` }}
            options={props.tabOptions}
            onChange={(e) => {
              setCurrentTab(e as ShowDataTabType);
              sendUserEvent({ button: `${props.tabModuleName}切换${Tabs[e]}模式查看` }, 'e_click');
              props.onTabChange(e as ShowDataTabType);
            }}
          />
        </div>
        {props.customControl && <div className={styles.customControl}>{props.customControl}</div>}
        <div className={styles.settingContainer}>
          {props.activeTab === ShowDataTabType.table && (
            <Button
              className={styles.settingButton}
              onClick={() => setDimensionTransferOpen(true)}
            >
              <JLLSvg
                icon="analytics"
                size={18}
              />
              <span>维度设置</span>
            </Button>
          )}
          {props.activeTab === ShowDataTabType.table && (
            <Button
              className={styles.settingButton}
              onClick={() => setTableControlOpen(true)}
            >
              <JLLSvg
                icon="table"
                size={18}
              />
              <span>表格设置</span>
            </Button>
          )}
          <AuthWrapWithTooltip
            loading={getLoading()}
            type="icon-button"
            auth={currentTab === 'detail' ? props.detailAuth : props.downloadAuth}
            buttonText="下载"
            className={styles.settingButton}
            icon="download"
            iconSize={18}
            align={{ offset: [-20, -10] }}
            onClick={downloadClick}
          />
        </div>
      </div>
      <div
        className={styles.content}
        id="CommonTableWrapContent"
      >
        <PageWithWatermark style={{ height: '100%' }}>
          <div style={{ height: '100%', overflowY: 'scroll' }}>{props.children}</div>
        </PageWithWatermark>
      </div>

      {dimensionTransferOpen && (
        <DimensionTransferModal
          open={dimensionTransferOpen}
          value={props.dimensionValues}
          list={props.dismensionList}
          onOk={(values) => {
            props.onDimensionChange(values);
            setDimensionTransferOpen(false);
          }}
          onCancel={() => setDimensionTransferOpen(false)}
        />
      )}
      {tableControlOpen && (
        <TableControlModal
          disableShowcategory={props.dimensionValues.length === 1}
          open={tableControlOpen}
          initialValues={props.tableFormValues}
          dataIndicatorOptions={props.dataIndicatorOptions}
          indicatorsRequired={props.indicatorsRequired}
          onOk={(values) => {
            props.onTableColumnChange(values);
            setTableControlOpen(false);
          }}
          onCancel={() => setTableControlOpen(false)}
        />
      )}
    </div>
  );
};

export default CommonTableWrap;
